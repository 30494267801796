
import { isMobileOnly, isTablet, isDesktop, browserName } from 'react-device-detect';


export function DeviceAndBrowserInfo() {
    let deviceType = "";
      
    if (isMobileOnly) {
      deviceType = "Mobile";
    } else if (isTablet) {
      deviceType = "Tablet";
    } else if (isDesktop) {
      deviceType = "Desktop";
    }
    
    // console.log(`Device detection results - Mobile: ${isMobileOnly}, Tablet: ${isTablet}, Desktop: ${isDesktop}`);
    // console.log(`Detected Device: ${deviceType}, Detected Browser: ${browserName}`);
  
    return {
      browserName: browserName,
      deviceType: deviceType,
    };
  }

  
