import { useState, useCallback } from "react";
import { APIPlayGame } from "../Api/GamesApi";

const useApiPlayGame = (
  userReduxData,
  setLoadingGameId,
  setPopupError,
  logout
) => {
  const [isLoading, setIsLoading] = useState(false);

  const APIPlayGameFunc = useCallback(
    async (id) => {
      // console.log("here call", id, userReduxData);
      if (!userReduxData) {
        logout();
        return;
      }

      setIsLoading(true);
      setLoadingGameId(id);
      let newWindow = window.open();

      try {
        const res = await APIPlayGame(id);
        if (res.status && res.message === "SUCCESS") {
          if (res.result.is_url) {
            newWindow.location = res?.result?.data;
          } else {
            const scriptString = res?.result?.data;
            const scripts = scriptString.split("</script>").filter(Boolean);
            scripts.forEach((scriptContent) => {
              const script = document.createElement("script");
              if (scriptContent.includes("src=")) {
                // External script
                const srcMatch = scriptContent.match(/src="([^"]+)"/);
                script.src = srcMatch[1];
                const integrityMatch =
                  scriptContent.match(/integrity="([^"]+)"/);
                script.integrity = integrityMatch ? integrityMatch[1] : null;
                const crossoriginMatch = scriptContent.match(
                  /crossorigin="([^"]+)"/
                );
                script.crossOrigin = crossoriginMatch
                  ? crossoriginMatch[1]
                  : null;
                document.head.appendChild(script);
              } else {
                // Inline script
                script.text = scriptContent
                  .replace(/<script type="text\/javascript">/, "")
                  .trim();
                document.head.appendChild(script);
              }
            });
          }
          return res; // Return the response here
        } else {
          newWindow.close();
          handleErrorResponse(res.response);
          return res; // Return the response here as well
        }
      } catch (error) {
        console.error("Failed to play game:", error);
        // Handle errors appropriately

        setPopupError(
          "An error occurred while trying to play the game. Please try again later."
        );
        return {
          message:
            "An error occurred while trying to play the game. Please try again later.",
        };
      } finally {
        setIsLoading(false);
        setLoadingGameId(null);
      }
    },
    [userReduxData, setLoadingGameId, setPopupError, logout]
  );

  const handleErrorResponse = (response) => {
    const { status, data } = response || {};
    if (status === 403) {
      if (data.message === "PLAYER_FORBIDDEN") {
        setPopupError(
          "Player is not allowed to play games, Please contact the customer support to activate your account."
        );
      } else if (data.message === "REGISTRATION_NETWORK_ERROR") {
        setPopupError("REGISTRATION NETWORK ERROR");
      } else if (data.message === "CURRENCY_NOT_SUPPORTED") {
        setPopupError("CURRENCY NOT SUPPORTED");
      } else {
        setPopupError(
          "Player is not allowed to play games, Please contact the customer support to activate your account."
        );
      }
    } else if (status === 401) {
      logout();
    } else if (status === 400) {
      if (data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else {
      setPopupError("An unexpected error occurred. Please try again later.");
    }

    // if (status === 403 && data?.message == "PLAYER_NOT_ALLOWED_TO_BET") {
    //   setPopupError(
    //     "You are not allowed to Bet, please contact customer support for assistance."
    //   );
    // } else if (status === 403 || status === 400) {
    //   setPopupError(
    //     data?.message || "An error occurred. Please try again later."
    //   );
    // } else if (status === 401) {
    //   logout();
    // } else {
    //   setPopupError("An unexpected error occurred. Please try again later.");
    // }
  };

  return {
    APIPlayGameFunc,
    isLoading,
  };
};

export default useApiPlayGame;
