import FilipinoIcon from "./assets/Language.png";
import BangladeshIcon from "./assets/images/flags/Bangladesh.png";

const configs = {
  client1: {
    name: "Bestway9 Filipino",
    countryCode: "+63",
    language: "Filipino",
    currency: "₱",
    currencyCode: "PHP",
    currencyBackendCode: "2",
    flagIcon: FilipinoIcon,
  },
  client2: {
    name: "Bestway9 Bangladesh",
    countryCode: "+880",
    language: "Bangla",
    currency: "৳",
    currencyCode: "BDT",
    currencyBackendCode: "16",
    flagIcon: BangladeshIcon,
  },
  // client3: {
  //   name: "Bestway9 Filipino",
  //   countryCode: "+63",
  //   language: "Filipino",
  //   captchaKey: "",
  //   currency: "₱",
  //   currencyCode: "PHP",
  //   currencyBackendCode: "2",
  //   flagIcon: FilipinoIcon,
  // },
  default: {
    name: "Bestway9 Default",
    countryCode: "+63",
    language: "Filipino",
    currency: "₱",
    currencyCode: "PHP",
    currencyBackendCode: "2",
    flagIcon: FilipinoIcon,
  },
};

export default configs;
