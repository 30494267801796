import React, { useEffect, useRef, useState } from "react";
import styles from "../Login/Login.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputField from "../../shared-components/inputField/InputField";
import UsernameIcon from "../../../assets/Username.png";
import PasswordIcon from "../../../assets/PasswordIcon.png";
import EyeOpen from "../../../assets/EyeOpenIcon.png";
import Eye from "../../../assets/EyeClosedIcon.png";
import NameIcon from "../../../assets/Name.png";
import PhoneIcon from "../../../assets/Language.png";
import CustomButton from "../../shared-components/custom-button/customButton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  APICheckPhoneExist,
  APICheckUsernameExists,
  APIRegisterUser,
} from "../../../Api/Apis";
import { setUser } from "../../../redux/Slice/UserSlice";
import { checkUserNameValidation } from "../../Helper/HelperFunction";
import SmallLoader from "../../shared-components/Loader/SmallLoader";
import { FaCheck } from "react-icons/fa6";
import ReCAPTCHA from "react-google-recaptcha";
import { getWebglFp } from "../../../assets/FingerPrint/main";
import FacebookIcon from "../../../assets/images/social-media/Facebook@2x.png";
import { DeviceAndBrowserInfo } from "../Login/DeviceAndBrowserInfo";
import clientConfig from "../../../config.json";
import BangladeshIcon from "../../../assets/images/flags/Bangladesh.png";

const flagIcons = {
  PHP: PhoneIcon,
  BDT: BangladeshIcon,
};

const Register = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const browser_url = window.location.href;
  const ag_id = localStorage.getItem("ag_id");

  const getFlagIcon = () => {
    if (clientConfig?.currencyCode === "BDT") {
      return flagIcons["BDT"];
    } else if (clientConfig?.currencyCode === "PHP") {
      return flagIcons["PHP"];
    }
    return null;
  };

  const [singupFormData, setSignupFormData] = useState({
    user_name: "",
    name: "",
    password: "",
    phone: "",
    currency: clientConfig.currencyBackendCode,
    agent_id: params?.ag_id || ag_id || "",
    countryCode: clientConfig?.countryCode,
  });

  const [singupErrors, setSignupErrors] = useState({
    user_name: "",
    password: "",
    name: "",
    phone: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const urlLocation = useLocation();
  const [signupBtnDisabled, setSignupBtnDisabled] = useState(false);
  const phoneInputRef = useRef(null);
  // const captchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  // const captchaKey = clientConfig?.captchaKey;
  const captchaKey = clientConfig?.captchaKey;

  const [singupFormDefaultMsg, setSingupFormDefaultMsg] = useState({
    defaultUserNameMsg: t("defaultUserNameMessage"),
    defaultPasswordMsg: t("defaultPasswordMessage"),
    defaultFullNameMsg: t("defaultFullNameMessage"),
    defaultPhoneMsg: t("defaultPhoneMessage"),
    USER_NAME_VALIDATION: t("USER_NAME_VALIDATION"),
    MINIMUM_NAME_VALIDATION: t("MINIMUM_NAME_VALIDATION"),
    MAXIMUM_NAME_VALIDATION: t("MAXIMUM_NAME_VALIDATION"),
    NAME_PATTERN_VALIDATION: t("NAME_PATTERN_VALIDATION"),
    PASSWORD_VALIDATION: t("PASSWORD_VALIDATION"),
    PHONE_NO_VALIDATION: t("PHONE_NO_VALIDATION"),

    // defaultUserNameMsg: "Minimum 8, Maximum 12 Characters. No Special Characters or Space Allowed.",
    // defaultPasswordMsg: "Minimum 6, Maximum 13 Characters. No Special Characters Allowed.",
    // defaultFullNameMsg: "Full name without any special characters. Eg: Alden Reyes",
    // defaultPhoneMsg: "Enter 10 digit phone number",
  });
  const reCaptchaRef = useRef(null);
  const [webGlResult, setWebGlResult] = useState(null);
  const containerRef = useRef(null);

  useEffect(() => {
    setWebGlResult(getWebglFp());
  }, []);

  const handleCaptchaError = () => {
    setErrorMessage(
      t(
        "User could not be verified. Please contact Customer Support. Error: 10001"
      )
    );
  };

  useEffect(() => {
    if (params && params?.ag_id) {
      localStorage.setItem("ag_id", params?.ag_id);
    }
  }, []);

  useEffect(() => {
    if (
      singupFormData?.user_name !== "" &&
      singupFormData?.name !== "" &&
      singupFormData?.password !== "" &&
      singupFormData?.phone !== ""
    ) {
      setSignupBtnDisabled(false);
    } else {
      setSignupBtnDisabled(true);
    }
  }, [singupFormData, singupErrors]);

  useEffect(() => {
    if (singupFormData?.user_name === "") {
      setSignupErrors((prevErrors) => {
        const { user_name, ...rest } = prevErrors;
        return rest;
      });
    }
    if (singupFormData?.phone === "") {
      setSignupErrors((prevErrors) => {
        const { phone, ...rest } = prevErrors;
        return rest;
      });
    }
    if (singupFormData?.password === "") {
      setSignupErrors((prevErrors) => {
        const { password, ...rest } = prevErrors;
        return rest;
      });
    }
    if (singupFormData?.name === "") {
      setSignupErrors((prevErrors) => {
        const { name, ...rest } = prevErrors;
        return rest;
      });
    }
  }, [singupFormData]);

  useEffect(() => {
    setSingupFormDefaultMsg({
      defaultUserNameMsg: t("defaultUserNameMessage"),
      defaultPasswordMsg: t("defaultPasswordMessage"),
      defaultFullNameMsg: t("defaultFullNameMessage"),
      defaultPhoneMsg: t("defaultPhoneMessage"),
      USER_NAME_VALIDATION: t("USER_NAME_VALIDATION"),
      MINIMUM_NAME_VALIDATION: t("MINIMUM_NAME_VALIDATION"),
      MAXIMUM_NAME_VALIDATION: t("MAXIMUM_NAME_VALIDATION"),
      NAME_PATTERN_VALIDATION: t("NAME_PATTERN_VALIDATION"),
      PASSWORD_VALIDATION: t("PASSWORD_VALIDATION"),
      PHONE_NO_VALIDATION: t("PHONE_NO_VALIDATION"),
    });
  }, [t]);

  const debounceAPICheckPhoneExist = async (phoneWithCountryCode, phone) => {
    if (phone === phoneInputRef.current?.value.substring(0, 10)) {
      const checkPhoneRes = await APICheckPhoneExist(phoneWithCountryCode);
      if (checkPhoneRes && checkPhoneRes.exists) {
        // setErrors(prevErrors => ({ ...prevErrors, phone: t("phoneerror") }));
        // return t("phoneerror");
        return t("Phone Already Taken");
      } else {
        // setErrors(prevErrors => ({ ...prevErrors, phone: ""}));
        return "";
      }
    }
    return "";
  };

  const userNameExistCheck = async (value) => {
    const userNameRes = await APICheckUsernameExists(value);
    if (userNameRes && userNameRes.exists) {
      return t("Username Already Taken");
    } else {
      return "";
    }
  };

  const validateUserName = async (value) => {
    const checkUserNameValidation = checkUserNameRegex(value.toLowerCase());
    if (!checkUserNameValidation) {
      return singupFormDefaultMsg.USER_NAME_VALIDATION;
    } else {
      const usernameExistMessage = await userNameExistCheck(
        value.toLowerCase()
      );
      return usernameExistMessage || "";
    }
  };

  const validateName = (value) => {
    const pattern = /^[a-zA-Z\s]+$/;
    const val = value.trim();
    const words = val.split(" ").filter((word) => word.length > 0);
    if (words.length < 2 || value?.length < 3) {
      return singupFormDefaultMsg.MINIMUM_NAME_VALIDATION;
    } else if (value && value?.length < 3) {
      return singupFormDefaultMsg.MINIMUM_NAME_VALIDATION;
    } else if (value?.length > 255) {
      return singupFormDefaultMsg.MAXIMUM_NAME_VALIDATION;
    } else if (!pattern.test(value)) {
      return singupFormDefaultMsg.NAME_PATTERN_VALIDATION;
    } else {
      return "";
    }
  };

  const validatePassword = (value) => {
    const checkPasswordValidation = checkPassaowrdRegex(value);
    if (!checkPasswordValidation) {
      return singupFormDefaultMsg.PASSWORD_VALIDATION;
    } else {
      return "";
    }
  };

  const validatePhone = async (phone) => {
    const checkPhoneValidation = checkPhoneLength(phone);
    if (!checkPhoneValidation) {
      return singupFormDefaultMsg.PHONE_NO_VALIDATION;
    }
    const phoneWithCountryCode = singupFormData.countryCode.concat(phone);
    const phoneExistMessage = await debounceAPICheckPhoneExist(
      phoneWithCountryCode,
      phone
    );
    return phoneExistMessage || "";
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const addClickListener = () => {
    document.addEventListener("click", handleClickOutside, true);
  };

  const removeClickListener = () => {
    document.removeEventListener("click", handleClickOutside, true);
  };

  const onFinish = async (e) => {
    e.preventDefault();
    setLoading(true);
    const info = DeviceAndBrowserInfo();
    // console.log("registered user device and browser",info);
    if (
      singupFormData.user_name &&
      singupFormData.name &&
      singupFormData.password &&
      singupFormData.phone
    ) {
      const user_name_error = await validateUserName(singupFormData.user_name);
      const name_error = validateName(singupFormData.name);
      const password_error = validatePassword(singupFormData.password);
      const phone_error = await validatePhone(singupFormData.phone);
      setErrors({
        user_name: user_name_error,
        name: name_error,
        password: password_error,
        phone: phone_error,
      });

      if (!user_name_error && !name_error && !password_error && !phone_error) {
        addClickListener();
        let captcha_token = await reCaptchaRef.current.executeAsync();
        reCaptchaRef.current.reset();
        removeClickListener();
        if (!captchaKey || !captcha_token) {
          setErrorMessage(
            t(
              "User could not be verified. Please contact Customer Support. Error: 10001"
            )
          );
          setLoading(false);
          return;
        }

        const res = await APIRegisterUser(
          singupFormData,
          captcha_token,
          webGlResult,
          info.deviceType,
          info.browserName,
          params,
          browser_url
        );
        if (res?.status === 200) {
          localStorage.setItem("auth_token", res?.data?.token);
          localStorage.removeItem("ag_id");
          dispatch(setUser(res?.data?.token));
          navigate("/");
          localStorage.removeItem("_grecaptcha");
        } else if (res?.status === 500) {
          setErrorMessage(t("Network Error"));
        } else if (res?.status === 404) {
          if (res?.data?.message === "AGENT_NOT_FOUND") {
            setErrorMessage(t("Agent Not Found"));
          }
        } else {
          if (res?.status === 422) {
            if (
              res?.data?.message ===
              "User could not be verified. Please contact Customer Support. Error: 10001"
            ) {
              setErrorMessage(res?.data?.message);
            } else if (
              res?.data?.message ===
              "Account could not be created because verification failed. Please contact Customer Support. Error: 10002"
            ) {
              setErrorMessage(res?.data?.message);
            }
            setErrors(Object.values(res?.data?.message));
          }
        }

        setErrors({});
        setSignupFormData({
          user_name: "",
          name: "",
          password: "",
          currency: clientConfig.currencyBackendCode,
          phone: "",
          agent_id: params?.ag_id || ag_id || "",
          countryCode: clientConfig?.countryCode,
        });
      }
      setLoading(false);
    }
  };

  //check name regex must be lowercase or numbers or both and the length between 8 and 12
  const checkUserNameRegex = (value) => {
    return /^(?:[a-z]|[0-9]){8,12}$/.test(value);
  };

  //check phone Number regex  must match this format (+)(number) and the number length between 11 and 14
  const checkPhoneLength = (value) => {
    return /^[0-9]{10}$/.test(value);
  };

  //The password can't have special characters and must contain lowercase, uppercase, numbers and the length between 8 and 18
  const checkPassaowrdRegex = (value) => {
    return /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};'"\\|,.<>\/?]{6,16}$/.test(value);
  };

  const checkValidation = () => !signupBtnDisabled;

  const inputBox = {
    maxWidth: "521px",
    width: "100%",
    height: "43px",
    background: "#000326 0% 0% no-repeat padding-box",
    border: "none",
    borderRadius: "7px",
    opacity: 1,
    textAlign: "left",
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "19px",
    fontFamily: "Roboto",
    letterSpacing: 0,
    color: "#FFFFFF",
  };
  return (
    <div className={` ${styles.formContainer}`}>
      <div className={styles.agentCodeWrapper}>
        {singupFormData?.agent_id && (
          <span>
            {" "}
            Agent Id: <strong>{singupFormData?.agent_id}</strong>
          </span>
        )}
      </div>
      <form
        ref={containerRef}
        className={styles.registerForm}
        onSubmit={onFinish}
      >
        <div>
          <InputField
            placeHolder={t("Your username")}
            extraStyle={{
              ...inputBox,
            }}
            type="text"
            name="user_name"
            prefix={
              <div style={{ width: 20, height: 20 }}>
                <img
                  src={UsernameIcon}
                  style={{
                    width: "100%",
                    height: "100%",
                    opacity: 1,
                    marginRight: "5px",
                  }}
                  preview={false}
                  alt="userIcon"
                />
              </div>
            }
            onChange={(name, value) =>
              setSignupFormData({
                ...singupFormData,
                user_name: value.toLowerCase(),
              })
            }
            onKeyPress={checkUserNameValidation}
            value={singupFormData.user_name}
            required={true}
            cls={styles.loginInputClass}
          />
          {/* <ErrorsHandlingComp errors={errors} name='user_name' /> */}
          {/* <div style={{ color:singupErrors?.user_name && singupErrors?.user_name.length ? "#FF5746" :"#A499E8", width: '100%' }}>{singupErrors?.user_name && singupErrors?.user_name.length ? singupErrors?.user_name : singupFormDefaultMsg.defaultUserNameMsg}</div> */}
          {Object.keys(errors).length === 0 ? (
            <span className={styles.inputInfoText} style={{ color: "#8F83DC" }}>
              {t(singupFormDefaultMsg.defaultUserNameMsg)}
            </span>
          ) : errors.user_name && errors.user_name.length ? (
            ""
          ) : (
            <span className={styles.inputInfoText} style={{ color: "#8F83DC" }}>
              {t(singupFormDefaultMsg.defaultUserNameMsg)}
            </span>
          )}
          {errors.user_name && (
            <span className={styles.inputInfoText} style={{ color: "#FF5746" }}>
              {errors.user_name}
            </span>
          )}
        </div>

        <div className="">
          <InputField
            placeHolder={t("Your password")}
            extraStyle={inputBox}
            type={showPassword ? "text" : "password"}
            cls={styles.loginInputClass}
            name="password"
            // onBlur={checkPassword}
            prefix={
              <div style={{ width: 20, height: 20 }}>
                <img
                  src={PasswordIcon}
                  style={{
                    width: "100%",
                    height: "100%",
                    opacity: 1,
                    marginRight: "5px",
                  }}
                  preview={false}
                  alt="passwordIcon"
                />
              </div>
            }
            suffix={
              showPassword ? (
                <img
                  src={EyeOpen}
                  style={{ width: 25, height: 25, opacity: 1 }}
                  onClick={() => setShowPassword(!showPassword)}
                  preview={false}
                  alt="eyeIcon"
                />
              ) : (
                <img
                  src={Eye}
                  style={{ width: 25, height: 25, opacity: 1 }}
                  onClick={() => setShowPassword(!showPassword)}
                  preview={false}
                  alt="eyeIcon"
                />
              )
            }
            onChange={(name, value) =>
              setSignupFormData({ ...singupFormData, password: value })
            }
            value={singupFormData.password}
            required={true}
          />
        </div>
        {/* <ErrorsHandlingComp errors={errors} name='password' /> */}
        {/* <div style={{ color:singupErrors?.password && singupErrors?.password.length ? "#FF5746" :"#A499E8", width: '100%' }}>{singupErrors?.password && singupErrors?.password.length ? singupErrors?.password : singupFormDefaultMsg.defaultPasswordMsg}</div> */}
        {Object.keys(errors).length === 0 ? (
          <span className={styles.inputInfoText} style={{ color: "#8F83DC" }}>
            {t(singupFormDefaultMsg.defaultPasswordMsg)}
          </span>
        ) : errors.password && errors.password.length ? (
          ""
        ) : (
          <span className={styles.inputInfoText} style={{ color: "#8F83DC" }}>
            {t(singupFormDefaultMsg.defaultPasswordMsg)}
          </span>
        )}
        {errors.password && (
          <span className={styles.inputInfoText} style={{ color: "#FF5746" }}>
            {errors.password}
          </span>
        )}

        <div>
          <InputField
            placeHolder={t("Your full name")}
            extraStyle={inputBox}
            cls={styles.loginInputClass}
            type="text"
            name="name"
            prefix={
              <div style={{ width: 20, height: 20 }}>
                <img
                  src={NameIcon}
                  style={{
                    width: "100%",
                    height: "100%",
                    opacity: 1,
                    marginRight: "5px",
                  }}
                  preview={false}
                  alt="nameIcon"
                />
              </div>
            }
            onChange={(name, value) =>
              setSignupFormData({ ...singupFormData, name: value })
            }
            value={singupFormData.name}
            required={true}
          />
        </div>
        {/* <ErrorsHandlingComp errors={errors} name='name' /> */}
        {/* <div style={{ color:singupErrors?.name && singupErrors?.name.length ? "#FF5746" :"#A499E8", width: '100%' }}>{singupErrors?.name && singupErrors?.name.length ? singupErrors?.name : singupFormDefaultMsg.defaultFullNameMsg}</div> */}
        {Object.keys(errors).length === 0 ? (
          <span className={styles.inputInfoText} style={{ color: "#8F83DC" }}>
            {t(singupFormDefaultMsg.defaultFullNameMsg)}
          </span>
        ) : errors.name && errors.name.length ? (
          ""
        ) : (
          <span className={styles.inputInfoText} style={{ color: "#8F83DC" }}>
            {t(singupFormDefaultMsg.defaultFullNameMsg)}
          </span>
        )}
        {errors.name && (
          <span className={styles.inputInfoText} style={{ color: "#FF5746" }}>
            {errors.name}
          </span>
        )}

        <div>
          <InputField
            placeHolder={t("Your phone number")}
            extraStyle={inputBox}
            cls={styles.loginInputClass}
            type="number"
            name="phone"
            // onKeyPress={checkUserNameValidation}
            prefix={
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <div style={{ width: 20, height: 20 }}>
                  <img
                    src={getFlagIcon()}
                    style={{
                      width: "100%",
                      height: "100%",
                      opacity: 1,
                      objectFit: "contain",
                    }}
                    preview={false}
                    alt="nameIcon"
                  />
                </div>
                <p className={styles.countryCodeText}>
                  {singupFormData?.countryCode}
                </p>
                <p
                  style={{
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: "medium",
                    fontSize: "20px",
                    color: "#574E85",
                    opacity: 1,
                    margin: "0px 5px 1px 0px",
                  }}
                >
                  |
                </p>
              </div>
            }
            // suffix={<CustomButton text="Get Code" type={styles.getCodeBtn} />}
            onChange={(name, value, target) => {
              phoneInputRef.current = target;
              value.length <= 10
                ? setSignupFormData({ ...singupFormData, phone: value })
                : setSignupFormData({
                    ...singupFormData,
                    phone: value.substring(0, 10),
                  });
            }}
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            value={singupFormData.phone}
            required={true}
          />
        </div>
        {/* <ErrorsHandlingComp errors={errors} name='phone' /> */}
        {Object.keys(errors).length === 0 ? (
          <span
            className={styles.inputInfoText}
            style={{ color: "#8F83DC", marginBottom: 18 }}
          >
            {t(singupFormDefaultMsg.defaultPhoneMsg)}
          </span>
        ) : errors.phone && errors.phone.length ? (
          ""
        ) : (
          <span
            className={styles.inputInfoText}
            style={{ color: "#8F83DC", marginBottom: 18 }}
          >
            {t(singupFormDefaultMsg.defaultPhoneMsg)}
          </span>
        )}
        {errors.phone && (
          <span
            className={styles.inputInfoText}
            style={{ color: "#FF5746", marginBottom: 18 }}
          >
            {errors.phone}
          </span>
        )}

        {errorMessage && (
          <span
            style={{
              color: "#FF5746",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {errorMessage}
          </span>
        )}
        <div style={{ display: "flex" }} className="register-checkbox">
          <input
            type="checkbox"
            required
            style={{ width: "6%", marginRight: 4 }}
          />
          <p className={styles.registerTerms}>
            {t(
              "By accessing you confirm that you are at least 18 years old and agree to the"
            )}{" "}
            <Link
              to={"/terms-and-conditions"}
              className={styles.termsServiceLink}
            >
              {t("Terms and service")}
            </Link>
          </p>
        </div>

        <ReCAPTCHA
          className="g-recaptcha"
          sitekey={captchaKey}
          size="invisible"
          ref={reCaptchaRef}
          onErrored={handleCaptchaError}
        />

        {loading ? (
          <SmallLoader height="36px" />
        ) : (
          <button
            type="submit"
            className={`${styles.loginBtnn}
                    ${
                      checkValidation() ? styles.activeButton : styles.disabled
                    }`}
          >
            {t("signUp")}
          </button>
        )}
      </form>

      {/* <p className={styles.orText}>Or</p>

      <button
        className={`${styles.facebookButton} flex items-center justify-center gap-2`}
      >
        <img className="w-6 h-6" src={FacebookIcon} alt={`facebook icon`} />

        {t("Signup with Facebook")}
      </button> */}
    </div>
  );
};

export default Register;
