import React, { useEffect, useState } from "react";
import styles from "./AppHeader.module.css";
import Announcement from "../../assets/images/announcement/Announcement.png";
import CustomMarquee from "../../components/shared-components/custom-marquee/CustomMarquee";
import SearchBar from "../../components/shared-components/searchBar/SearchBar";
import CustomButton from "../../components/shared-components/custom-button/customButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../assets/images/back-icon/BackIcon.png";
import { useSelector } from "react-redux";
import {
  CommaSeperator,
  toFixedFunc,
} from "../../components/Helper/HelperFunction";
import LogoutConfirmationModal from "./LogoutConfirmationModal";
import balanceIcon from "../../assets/images/payment icons/Balance.png";
import exitIcon from "../../assets/Exit.png";
import CustomButtonAnt from "../../components/shared-components/custom-button/CustomButtonAnt";
import langImage from "../../assets/Language@2x.png";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../Sidebar/components/LanguageSwitcher";
import homeicon from "../../assets/images/back-icon/Active Home.png";
import { currencyHelper } from "../../components/Helper/currencyHelper";

const AppHeader = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [logoutModal, setLogoutModal] = useState(false);
  const userToken = useSelector((state) => state.user.user);
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const segments = pathname.split("/");
  const lastSegment = segments[segments.length - 1];
  const title = lastSegment
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  const promotionTitle = location.state?.promotionTitle;
  const currentLanguage = localStorage.getItem("selectedLanguage");

  const handleSearchInputChange = (value) => {
    setSearchValue(value);
  };

  const marqText = t("headerText");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div
      className={`${
        pathname?.toLowerCase().includes("login") ||
        pathname?.toLowerCase() === "/register"
          ? ""
          : styles.headerContainer
      } 
      sticky top-0 flex items-center z-[100] justify-between h-16 border-l-2 border-[#5859F2] bg-transparent`}
    >
      {pathname?.toLowerCase().includes("login") ||
      pathname?.toLowerCase() === "/register" ? (
        <>
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              height: "100%",
              padding: "0 20px",
              position: "relative",
            }}
          >
            {/* <div style={{ width: "40px", height: "40px"}}>
              <img
                src={homeicon}
                style={{ cursor: "pointer" }}
                className="imgStyle"
                // onClick={() => navigate(-1)}
                onClick={() => (userToken ? navigate(-1) : navigate("/"))}
                alt="back button"
              />
            </div>

            <div
              style={{
                width: "36px",
                height: "36px",
                position: "absolute",
                right: 30,
                background: "#3D3279",
                borderRadius: 5,
                opacity: 1,
                // zIndex : 999,
              }}
            >
              <LanguageSwitcher />
            </div> */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "40px",
                  height: "40px",
                  left: "10px",
                  top: "10px",
                }}
              >
                <img
                  src={homeicon}
                  style={{ cursor: "pointer" }}
                  className="imgStyle"
                  // onClick={() => navigate(-1)}
                  onClick={() => (userToken ? navigate(-1) : navigate("/"))}
                  alt="back button"
                />
              </div>

              <div
                style={{
                  width: "36px",
                  height: "36px",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "#3D3279",
                  borderRadius: 5,
                  opacity: 1,
                  zIndex: 999,
                }}
              >
                <LanguageSwitcher />
              </div>
            </div>

            <div
              style={{
                margin: "auto",
              }}
            >
              <span
                style={{
                  fontStyle: "normal",
                  fontVariant: "normal",
                  fonSeight: "normal",
                  fontSize: "24px",
                  lineHeight: "29px",
                  fontFamily: "Roboto",
                  letterSpacing: "0px",
                  // color: "#FFFFFF",
                  color: "red",
                  opacity: 1,
                }}
              >
                {pathname.includes("/promotions/" + title)
                  ? promotionTitle
                  : pathname.includes("/transections/" + title)
                  ? "Transaction"
                  : pathname?.toLowerCase().includes("/login") ||
                    pathname?.toLowerCase().includes("/register")
                  ? ""
                  : title}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className="flex  justify-between w-[100%]">
          <div className="flex items-center px-4 w-[50%] gap-4">
            <img className="w-7 h-7" src={Announcement} alt="Logo" />
            <div className={styles.paddingWrapper}>
              <div className={styles.marqueOverlay}>
                <CustomMarquee
                  cls={styles.marqueeWrapper}
                  text={t(`${marqText}`)}
                  textStyle={styles.textStyling}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2 pr-4">
            {/* <div className="my-4 w-[170px] md:w-[270px]">
              <SearchBar
                width="270px"
                height="36px"
                background="#3D3279"
                borderRadius="5px"
                opacity={1}
                placeholder="Search For Games..."
                onInputChange={handleSearchInputChange}
              />
            </div> */}
            {userToken ? (
              <div className={styles.balanceExitContainer}>
                {/* <div
                  className={styles.notificationStyle}
                  style={{ marginTop: 5 }}
                >
                  <Badge
                    count={notificationCount}
                    style={{
                      backgroundColor: "#5158DE",
                      color: "white",
                      boxShadow: "0 0 0 1px #5158DE inset",
                    }}
                  >
                    <img
                      src={NotificationIcon}
                      style={{ cursor: "pointer" }}
                      className="imgStyle"
                      alt="notification"
                      onClick={() => navigate("/notifications")}
                    />
                  </Badge>
                </div> */}
                {/* <div className={styles.balanceCoinsContainer}>
                  <div className={styles.coinsStyle}>
                    <img src={balanceIcon} className="imgStyle" alt="balance" />
                  </div>
                  <div className={styles.balanceTextContainer}>
                    <p className={styles.balanceText}>
                      {userInfoReduxData?.currency === "PHP" && "₱"}
                    </p>
                    <p className={styles.balanceText}>
                      {userInfoReduxData?.balance &&
                        CommaSeperator(toFixedFunc(userInfoReduxData?.balance))}
                    </p>
                  </div>
                </div> */}
                <div>
                  <Link to="/account">
                    <CustomButtonAnt
                      className="pr-2"
                      width="100%"
                      height="36px"
                      color="#FFFFFF"
                      icon={balanceIcon}
                    >
                      <div className={styles.balanceTextContainer}>
                        <p className={styles.balanceText}>
                          {currencyHelper(userInfoReduxData?.currency)}
                        </p>
                        <p className={styles.balanceText}>
                          {userInfoReduxData?.balance &&
                            CommaSeperator(
                              toFixedFunc(userInfoReduxData?.balance)
                            )}
                        </p>
                      </div>
                    </CustomButtonAnt>
                  </Link>
                </div>
                <div onClick={() => setLogoutModal(true)}>
                  <CustomButtonAnt
                    width="fit-content"
                    height="36px"
                    color="#FFFFFF"
                    icon={exitIcon}
                  >
                    {t(`Logout`)}
                  </CustomButtonAnt>
                </div>
                {/* <LanguageSwitcher/> */}
              </div>
            ) : (
              <>
                <div>
                  <Link to="/login">
                    <CustomButton
                      background="transparent linear-gradient(180deg, #5158DE 0%, #613ADE 48%, #711ADE 100%) 0% 0% no-repeat padding-box"
                      width="100px"
                      height="36px"
                      color="#FFFFFF"
                    >
                      {t(`Login`)}
                    </CustomButton>
                  </Link>
                </div>
                <div>
                  <Link to="/register">
                    <CustomButton
                      background="transparent linear-gradient(180deg, #FFFFFF 0%, #AAAAAA 100%) 0% 0% no-repeat padding-box"
                      width="100px"
                      height="36px"
                      color="#1A172E"
                    >
                      {t(`signUp`)}
                    </CustomButton>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {logoutModal && (
        <LogoutConfirmationModal
          logoutModal={logoutModal}
          closeLogoutModal={() => setLogoutModal(false)}
        />
      )}
    </div>
  );
};

export default AppHeader;
