import { useEffect } from 'react';

function useControlBodyOverflow(value) {
  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = value ? 'hidden' : 'auto';
    return () => {
      body.style.overflow = 'auto'; // Reset overflow when component unmounts
    };
  }, [value]);
}

export default useControlBodyOverflow;