import styles from "./LogoutConfirmationModal.module.css";
import LogoutImg from "../../assets/Logout2.png";
import useLogout from "./useLogout";
import { useTranslation } from "react-i18next";

const LogoutConfirmationModal = ({ logoutModal, closeLogoutModal }) => {
  const { t } = useTranslation();

  const logout = useLogout();
  return (
    <div className={`${styles.modalOverlay} fixed z-50 inset-0 `}>
      <div className="flex items-center justify-center min-h-screen">
        <div onClick={closeLogoutModal} className={styles.logoutOverlay}>
          <div className={styles.logoutImgwrapper}>
            <div>
              <img src={LogoutImg} alt="logout" width={45} />
            </div>
            <p> {t(`Logout`)}</p>
          </div>
          <div className={styles.logoutMsg}>{t(`logoutMsg`)}</div>
          <div className={styles.logoutButtons}>
            <button onClick={closeLogoutModal}>{t(`NoStay`)} </button>
            <button
              onClick={() => {
                closeLogoutModal();
                logout();
              }}
            >
              {t(`YesLeave`)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutConfirmationModal;
