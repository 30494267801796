import React, { useEffect, useState } from "react";
import styles from "./Withdraw.module.css";
import addEWallet from "../../../assets/images/payment icons/Add E-Wallet.png";
import GCash from "../../../assets/images/payment icons/GCash.png";
import selectedIcon from "../../../assets/images/payment icons/Selected Method.png";
import WithdrawInput from "./components/WithdrawInput";
import {
  CommaSeperator,
  handleKeyPress,
  toFixedFunc,
} from "../../Helper/HelperFunction";
import {
  WithdrawAPI,
  WithdrawStatusAPI,
  getWithdrawPaymentMethodApi,
  withdrawAllowed,
} from "../../../Api/TransactionApi";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useLogout from "../../../layouts/Header/useLogout";
import { setWithdrawAmount } from "../../../redux/Slice/WithdrawSlice";
import PaymentMethod from "./components/PaymentMethod";
import SmallLoader from "../../shared-components/Loader/SmallLoader";
import LoginPopupModal from "../Login/LoginPopupModal";
import RecentWithdrawals from "./components/RecentWithdrawals";
import { setUserInfo } from "../../../redux/Slice/UserSlice";
import { APIUser } from "../../../Api/Apis";
import OtpPopupModal from "./components/OtpPopup";

const inputBox = {
  width: "100%",
  height: "50px",
  background: "#000326 0% 0% no-repeat padding-box",
  border: "1px solid #3C3562",
  borderRadius: "7px",
  opacity: 1,
  textAlign: "left",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontSize: "18px",
  lineHeight: "19px",
  fontFamily: "Roboto",
  letterSpacing: 0,
  color: "#FFFFFF",
};

const Withdraw = () => {
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const withdrawamountReduxData = useSelector(
    (state) => state?.withdraw?.withdrawamount
  );
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [userBankMethods, setUserBankMethods] = useState(null);
  const [withdrawMinAmount, setWithdrawMinAmount] = useState(100);
  const [withdrawMaxAmount, setWithdrawMaxAmount] = useState(50000);
  const [amount, setAmount] = useState(withdrawamountReduxData || "");
  const [noAmountError, setNoAmountError] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(true);
  const [loading, setLoading] = useState(false);
  const [methodLoading, setMethodLoading] = useState(false);
  const [popupError, setPopupError] = useState("");
  const [checkAvailableWallet, setCheckAvailableWallet] = useState(false);
  const [turnoverData, setTurnoverData] = useState(null);

  const [isFirstWithdraw, setIsFirstWithdraw] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [otpPopupShow, setOtpPopupShow] = useState(false);
  const [phone, setPhone] = useState();
  const [finalSucess, setFinalSuccess] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = useLogout();

  const handleInputChange = (name, value) => {
    if (userBankMethods?.length === 0) {
      setCheckAvailableWallet(true);
    }
    setAmount(value);
    dispatch(setWithdrawAmount(value));
  };

  const userInfoApi = async () => {
    const userInfoApiRes = await APIUser();
    if (userInfoApiRes?.response?.status === 401) {
      logout();
    } else if (userInfoApiRes?.response?.status === 400) {
      if (userInfoApiRes?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else {
      dispatch(setUserInfo(userInfoApiRes));
    }
  };

  const setTurnoverDataCallback = (data) => {
    setTurnoverData(data);
  };

  // check withdraw Allow API
  const checkWithdrawAllow = async () => {
    const res = await withdrawAllowed();
    if (res?.status === 200) {
      if (res?.data?.message === "PLAYER_NOT_ALLOWED_TO_WITHDRAW") {
        setPopupError(t(`withdrawFeatureLock`));
      } else {
        setIsWithdraw(res.data.status);
      }
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else if (res?.response?.status === 401) {
      logout();
    }
  };
  const getWithdrawPaymentMethodFunc = async () => {
    setMethodLoading(true);
    const res = await getWithdrawPaymentMethodApi();
    if (res.status === 200) {
      setUserBankMethods(res.data);
      setSelectedMethod(res.data[0]);
    } else if (res?.response?.status === 401) {
      logout();
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    }
    setMethodLoading(false);
  };

  // check withdraw allowed or not, active step check
  useEffect(() => {
    getWithdrawPaymentMethodFunc();
    checkWithdrawAllow();

    return () => {};
  }, []);

  useEffect(() => {
    if (selectedMethod) {
      selectedMethod?.min_deposit_amount &&
        setWithdrawMinAmount(selectedMethod?.min_deposit_amount);
      selectedMethod?.max_deposit_amount &&
        setWithdrawMaxAmount(selectedMethod?.max_deposit_amount);
    }
  }, [selectedMethod]);

  const addWallet = () => {
    userBankMethods?.length < 3 && !loading && navigate("/account/ewallet");
  };

  //  {/* if in case res?.response?.data?.message or anything is changing by backend the we need to translate the conditions too. so need to complete backend first */}

  const submitWithdraw = async (e) => {
    if (!checkValidation()) {
      setLoading(true);
      const res = await WithdrawAPI(
        selectedMethod?.id,
        amount || withdrawamountReduxData
      );
      // console.log("qqqqqq", res);

      if (res.status === 200) {
        setLoading(false);
        // dispatch(setWithdrawAmount(""));
        userInfoApi();
        navigate("/account/transactions", { state: { active: "withdraw" } });
      } else if (res?.response?.status === 401) {
        logout();
      } else if (res?.response?.data?.message === "INVALID_AMOUNT") {
        setPopupError(
          `Enter a valid amount between ${CommaSeperator(
            toFixedFunc(withdrawMinAmount)
          )} - ${CommaSeperator(
            toFixedFunc(withdrawMaxAmount)
          )} to make withdraw request.`
        );
      } else if (res?.response?.status === 400) {
        if (res?.response?.data?.message === "INSUFFICIENT_BALANCE") {
          setPopupError(
            t("Dont have enough balance to make a withdrawal Request")
          );
        } else if (res?.response?.data?.message === "INVALID_ACCESS") {
          logout();
        } else if (
          res?.response?.data?.message === "FINANCE_VERIFICATION_FAILED"
        ) {
          navigate("/account/transactions", { state: { active: "withdraw" } });
        }
      } else if (
        res?.response?.data?.message === "PLAYER_NOT_ALLOWED_TO_WITHDRAW"
      ) {
        setPopupError("withdrawFeatureLock");
      } else if (res?.response?.data?.message === "INSUFFICIENT_BALANCE") {
        // setPopupError(t("notEnoughBalanceErr"));
        setPopupError(t("Insufficient Balance"));
      } else if (res?.response?.status === 403) {
        setPopupError(`pendingreqerror`);
      } else if (res?.response?.data?.message === "Account Not Activated") {
        setPopupError(t("depositFirstErrMessage"));
      } else if (
        res?.response?.data?.message === "WITHDRAW_AMOUNT_BELOW_MINIMUM"
      ) {
        setPopupError(
          `${t("minWidthdrawAmmErrPart1")} ${withdrawMinAmount} ${t(
            "minWidthdrawAmmErrPart1"
          )}`
        );
      } else if (res?.response?.status === 422) {
        setPopupError("withdrawFeatureLock");
      } else {
        setPopupError(t("errormessage"));
      }
      setLoading(false); // Make sure to set loading to false here
      dispatch(setWithdrawAmount(""));
    }
  };

  const handleSelectCategory = (e, method) => {
    setSelectedMethod(method);
  };

  const checkValidation = () => {
    if (
      !(Number(amount) || Number(withdrawamountReduxData)) ||
      (Number(amount) || Number(withdrawamountReduxData)) >
        userInfoReduxData?.balance ||
      (Number(amount) || Number(withdrawamountReduxData)) < withdrawMinAmount ||
      (Number(amount) || Number(withdrawamountReduxData)) > withdrawMaxAmount ||
      amount.includes(".") ||
      !isWithdraw ||
      !selectedMethod ||
      turnoverData?.remainingTurnOver > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const closePopup = () => {
    setPopupError("");
    setLoading(false);
    if (popupError === "withdrawFeatureLock") {
      navigate("/support");
    } else if (popupError === "pendingreqerror") {
      navigate("/account/transactions", { state: { active: "withdraw" } });
    } else {
      navigate("/");
    }
  };

  const closeOtpPopup = () => {
    setOtpPopupShow(false);
  };

  const checkStatus = async () => {
    setLoading(true);
    const res = await WithdrawStatusAPI();
    // console.log("x", res);
    setLoading(false);
    if (res.status === 200) {
      setIsFirstWithdraw(res?.data?.is_first_withdraw);
      setIsUserVerified(res?.data?.is_contact_verified);
    }
  };

  // FOR OTP Verification

  useEffect(() => {
    if (userInfoReduxData) {
      checkStatus();
      const phoneNo = userInfoReduxData?.phone;
      const removeCountryCode = phoneNo
        ? phoneNo?.startsWith("+")
          ? phoneNo?.slice(3)
          : phoneNo
        : "";
      setPhone(removeCountryCode);
    }
  }, [userInfoReduxData]);

  useEffect(() => {
    if (finalSucess) {
      submitWithdraw();
      setNoAmountError(!amount ? true : false);
      setFinalSuccess(false);
      checkStatus();

      const phoneNo = userInfoReduxData?.phone;
      const removeCountryCode = phoneNo
        ? phoneNo?.startsWith("+")
          ? phoneNo?.slice(3)
          : phoneNo
        : "";
      setPhone(removeCountryCode);
    }
  }, [finalSucess]);

  return (
    <div className={`m-5 min-h-[70vh] pb-5`}>
      <p className={`text-3xl ${styles.headTitleDesign}`}>{t(`Withdraw`)}</p>
      <div className={` ${styles.container} w-[100%]`}>
        <div className={`${styles.cardWrapper} w-[100%] relative `}>
          <PaymentMethod
            userBankMethods={userBankMethods}
            handleSelectCategory={handleSelectCategory}
            selectedMethod={selectedMethod}
            selectedIcon={selectedIcon}
            methodLoading={methodLoading}
            loading={loading}
            addWallet={addWallet}
            addEWallet={addEWallet}
            type={"withdraw"}
          />
          <WithdrawInput
            inputBox={inputBox}
            amount={amount}
            userInfoReduxData={userInfoReduxData}
            withdrawamountReduxData={withdrawamountReduxData}
            handleInputChange={handleInputChange}
            handleKeyPress={handleKeyPress}
            withdrawMinAmount={withdrawMinAmount}
            withdrawMaxAmount={withdrawMaxAmount}
            noAmountError={noAmountError}
            setTurnoverDataCallback={setTurnoverDataCallback}
          />

          <div>
            {checkAvailableWallet && userBankMethods?.length === 0 && (
              <p
                style={{
                  color: "#ff5746",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {t(`No wallet selected. Please add a wallet to continue.`)}
              </p>
            )}
          </div>

          {loading ? (
            <SmallLoader />
          ) : (
            <div className="pl-5 pb-5">
              <button
                disabled={checkValidation()}
                onClick={(e) => {
                  submitWithdraw(e);
                  setNoAmountError(!amount ? true : false);
                  // if (isFirstWithdraw) {
                  //   if (!isUserVerified) {
                  //     setOtpPopupShow(true);
                  //     const phoneNo = userInfoReduxData?.phone;
                  //     const removeCountryCode = phoneNo
                  //       ? phoneNo?.startsWith("+")
                  //         ? phoneNo?.slice(3)
                  //         : phoneNo
                  //       : "";
                  //     setPhone(removeCountryCode);
                  //   } else {
                  //     submitWithdraw(e);
                  //     setNoAmountError(!amount ? true : false);
                  //   }
                  // } else {
                  //   submitWithdraw(e);
                  //   setNoAmountError(!amount ? true : false);
                  // }
                }}
                className={`${styles.withdrawBtn}
              ${
                checkValidation()
                  ? styles.disabled
                  : styles.withdrawButtonActive
              }`}
              >
                {t(`Withdraw`)}
              </button>
            </div>
          )}
        </div>

        {/* <div className="wrapper w-[30%]">
          <h1 className="text-xl text-white">Recent Withdrawal</h1>
          <div className={styles.card}>No Withdrawal Requests</div>
          <button>More</button>
        </div>

        {/* <div className="w-[20%] overflow-y-auto scrollbar">
          <RecentWithdrawals/>
        </div> */}
      </div>
      {popupError && (
        <LoginPopupModal
          message={t(popupError)}
          show={popupError ? true : false}
          errorIcon={popupError ? true : false}
          hideModal={closePopup}
        />
      )}

      {otpPopupShow && (
        <OtpPopupModal
          hideModal={closeOtpPopup}
          phone={phone}
          setPhone={setPhone}
          setFinalSuccess={setFinalSuccess}
        />
      )}
    </div>
  );
};

export default Withdraw;
