import React, { useEffect, useState } from "react";
import styles from "./Bonus.module.css";
import { promotionsAPI } from "../../../Api/PromotionApi";
import CustomButton from "../../Helper/CustomButton";
import Loader from "../../shared-components/Loader/SmallLoader";
import NoDataComp from "../../shared-components/NoData/NoDataComp";
import { Link, useNavigate } from "react-router-dom";
import backIcon from "../../../assets/images/back-icon/BackIcon.png";

const Bonus = () => {
  const country = process.env.REACT_APP_CountryCode;
  const [selectedButton, setSelectedButton] = useState("All");
  const navigate = useNavigate();
  const [promotionData, setPromotionData] = useState([]);
  const [promoData, setPromoData] = useState([]);
  const [loading, setLoading] = useState(false);

  const promotionDataFunc = (selectedId = "All") => {
    setSelectedButton(selectedId);
    if (selectedId === "All") {
      let uniqueIds = new Set();
      const allPromotions = promotionData.flatMap((item) => item.promotions);
      let filterPromotion = allPromotions.filter((promo) => {
        const id = promo?.pivot?.promotion_id;
        if (!uniqueIds.has(id)) {
          uniqueIds.add(id);
          return true;
        }
        return false;
      });
      setPromoData(filterPromotion);
    } else {
      const selectedCategory = promotionData.find(
        (obj) => obj.id === selectedId
      );
      setPromoData(selectedCategory ? selectedCategory.promotions : []);
    }
  };
  const getPromotionDataFunc = async () => {
    setLoading(true);
    const res = await promotionsAPI(2);
    if (res) {
      setPromotionData(res);
      const allPromotions = res.flatMap((item) => item.promotions);
      let uniqueIds = new Set();
      let filterPromotion = allPromotions.filter((promo) => {
        const id = promo?.pivot?.promotion_id;
        if (!uniqueIds.has(id)) {
          uniqueIds.add(id);
          return true;
        }
        return false;
      });
      setPromoData(filterPromotion);
    } else {
      setPromotionData([]);
      setPromoData([]);
    }

    // promotionDataFunc();
    setLoading(false);
  };

  useEffect(() => {
    promotionDataFunc(selectedButton);
  }, [selectedButton, promotionData]);

  useEffect(() => {
    getPromotionDataFunc();
  }, []);

  return (
    <>
      <div className="flex justify-normal  mt-4 ml-3 space-x-5">
        <div>
          <Link to="/">
            <img src={backIcon} alt="back" />
          </Link>
        </div>
        <div>
          <p className="text-xl font-medium text-white">Promotions</p>
        </div>
      </div>
      <div className={styles.container}>
        <div
          className={styles.buttonsContainer}
          style={{
            flexWrap: promotionData && promotionData.length > 2 ? "wrap" : "",
            justifyContent:
              promotionData && promotionData.length > 2 ? "space-evenly" : "",
          }}
        >
          {promotionData && promotionData.length ? (
            <>
              <div
                className={styles.buttonWrapper}
                style={{
                  maxWidth:
                    promotionData && promotionData.length > 2 ? "120px" : "",
                }}
              >
                <CustomButton
                  text="All"
                  className="no-hover"
                  type={`${styles.buttonsStyle} ${
                    selectedButton === "All"
                      ? styles.activeBtnBg
                      : styles.inactiveBtnBg
                  }`}
                  onClick={() => promotionDataFunc("All")}
                />
              </div>
              {promotionData.map((category) => {
                const categoryName = category?.name?.en;
                return (
                  <div
                    className={styles.buttonWrapper}
                    key={category.id}
                    style={{
                      maxWidth:
                        promotionData && promotionData.length > 2
                          ? "120px"
                          : "",
                    }}
                  >
                    <CustomButton
                      text={categoryName}
                      className="no-hover"
                      type={`${styles.buttonsStyle} ${
                        selectedButton === category.id
                          ? styles.activeBtnBg
                          : styles.inactiveBtnBg
                      }`}
                      onClick={() => promotionDataFunc(category.id)}
                    />
                  </div>
                );
              })}
            </>
          ) : (
            ""
          )}
        </div>
        {loading ? (
          <Loader />
        ) : promotionData && promotionData.length ? (
          <div className={styles.imageContainer}>
            {promoData &&
              promoData.map((image) => (
                <div
                  className={styles.imageSubCotainer}
                  key={image.title}
                  onClick={() =>
                    navigate(`/promotions/${image?.pivot?.promotion_id}`, {
                      state: {
                        promotionTitle: image.title
                          ? image.title
                          : "Promotions",
                      },
                    })
                  }
                >
                  <img
                    alt={image.title}
                    src={image.full_image}
                    loading="lazy"
                    style={{ borderRadius: "10px" }}
                    className="imgStyle"
                  />
                </div>
              ))}
          </div>
        ) : (
          <NoDataComp />
        )}
      </div>
    </>
  );
};

export default Bonus;

// (promotionData.length ?
//     <div className={styles.imageContainer}>
//         {ImagesData.map((image) =>
//             <img alt={image.id} src={image.img} loading="lazy" className='imgStyle' style={{ borderRadius: 10 }} key={image.id} />
//         )}
//     </div>
//     :
//     <div className={styles.noDatFoundContainer}>
//         <div style={{ width: 50, height: 40 }}>
//             <img alt="NotFound" src={NoDataFoundIcon} className='imgStyle' />
//         </div>
//     </div>

// )
