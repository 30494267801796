import React, { useEffect, useState } from "react";
import styles from "./SinglePromotionDetails.module.css";
import Loader from "../../shared-components/Loader/SmallLoader";
import { promotionsAPI } from "../../../Api/PromotionApi";
import { Link, useParams } from "react-router-dom";
import NoDataComp from "../../shared-components/NoData/NoDataComp";
import DOMPurify from "dompurify";
import backIcon from "../../../assets/images/back-icon/BackIcon.png";

const SinglePromotionDetails = () => {
  const country = process.env.REACT_APP_CountryCode;
  const [loading, setLoading] = useState(false);
  const [promotionData, setPromotionData] = useState({});
  const { id } = useParams();
  const numericId = Number(id);

  const getPromotionDataFunc = async () => {
    setLoading(true);
    const res = await promotionsAPI(2);
    if (res) {
      setPromotionData(res);
      const allPromotions = res.flatMap((item) => item.promotions);
      const singlePromoDetails = allPromotions.find(
        (item) => item?.pivot?.promotion_id === numericId
      );
      if (singlePromoDetails) {
        setPromotionData(singlePromoDetails);
      } else {
        setPromotionData({});
      }
    } else {
      setPromotionData({});
    }

    // promotionDataFunc();
    setLoading(false);
  };

  useEffect(() => {
    getPromotionDataFunc();
  }, []);

  const renderHTML = () => {
    if (!promotionData?.body) {
      return null;
    }
    // Sanitize HTML content using DOMPurify
    const sanitizedHtml = DOMPurify.sanitize(promotionData.body, {
      ADD_TAGS: ["iframe"],
    });

    return { __html: sanitizedHtml };
  };
  return (
    <>
    <div className="flex justify-normal  mt-4 ml-3 space-x-5">
        <div>
          <Link to="/">
            <img src={backIcon} alt="back"  />
          </Link>
        </div>
        <div>
          <p className="text-xl font-medium text-white">Promotions Title</p>
        </div>
      </div>
      <div className={styles.mainContainer}>
        {loading ? (
          <Loader />
        ) : Object.keys(promotionData).length > 1 ? (
          <>
            {/* <div className={styles.titleWrapper}>
                        <h3>{promotionData?.title}</h3>
                    </div> */}

            <div className={styles.imageContainer}>
              <img
                alt={promotionData?.title}
                src={promotionData?.full_image}
                loading="lazy"
                style={{ borderRadius: "10px" }}
                className="imgStyle"
              />
            </div>

            {promotionData?.body ? (
              <div className={styles.bodyContainer}>
                {/* {promotionData?.body} */}
                <div dangerouslySetInnerHTML={renderHTML()} />
              </div>
            ) : (
              <NoDataComp />
            )}
          </>
        ) : (
          <NoDataComp />
        )}
      </div>
    </>
  );
};

export default SinglePromotionDetails;
