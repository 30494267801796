import Account from "../components/pages/Account/Account";
import AddPaymentMethod from "../components/pages/AddPaymentMethod/AddPaymentMethod";
import Deposit from "../components/pages/Deposit/Deposit";
import Withdraw from "../components/pages/Withdraw/Withdraw";
import Notifications from "../components/pages/Account/components/Notifications";
import EwalletManagement from "../components/pages/Account/components/EwalletManagement";
import Transactions from "../components/pages/Transactions/Transactions";
import BettingRecord from "../components/pages/BetttingRecord/BettingRecord";
import InviteFriends from "../components/pages/InviteFriends/InviteFriends";
import PageNotFound from "../components/pages/PageNotFound/PageNotFound";

export const privateRoutes = [
  {
    id: 1,
    path: "/deposit",
    component: Deposit,
  },
  {
    id: 2,
    path: "/withdraw",
    component: Withdraw,
  },
  {
    id: 3,
    path: "/account/transactions",
    component: Transactions,
  },
  {
    id: 4,
    path: "/account/ewallet",
    component: EwalletManagement,
  },
  {
    id: 5,
    path: "/notifications",
    component: Notifications,
  },
  {
    id: 6,
    path: "/account",
    component: Account,
  },
  {
    id: 7,
    path: "/account/ewallet",
    component: AddPaymentMethod,
  },
  {
    id: 8,
    path: "/transections/:id",
    component: Transactions,
  },
  {
    id: 9,
    path: "/account/betting-records",
    component: BettingRecord,
  },
  {
    id: 10,
    path: "/account/invite-friends",
    component: InviteFriends,
  },
  {
    id: 11,
    path: "*",
    component: PageNotFound,
  },
];
