import React, { useEffect, useState } from "react";
import Banner from "../../shared-components/banner/Banner";
import {
  getCatergoriesWithGames,
  getGameProviders,
} from "../../../Api/GamesApi";
import useLogout from "../../../layouts/Header/useLogout";
import { useSelector } from "react-redux";
import { selectActiveCatId } from "../../../redux/Slice/CategoriesSlice";
import CategoryAndGameSection from "./CategoryAndGameSection/CategoryAndGameSection";
import { useLocation } from "react-router-dom";
import { act } from "react";
import Loader from "../../shared-components/Loader/Loader";
import AgeVerificationPopup from "../../shared-components/AgeVerificationModal/AgeVerificationPopup";
import MainPageLoading from "../../shared-components/MainPageLoading/MainPageLoading";
import clientConfig from "../../../config.json";

const Home = () => {
  const [showLoader, setShowLoader] = useState(false);
  const allCategories = useSelector(
    (stete) => stete?.categories?.allCategories
  );
  const activeCatRedux = useSelector((state) => state?.categories?.activeCatId);
  const location = useLocation();
  // Extract query parameters from the URL
  const trendingGames = useSelector((state) => state?.trending?.trendingGames);
  const [activeCat, setActiveCat] = useState(0);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [multipleSelectedPlatforms, setMulitpleSelectedPlatforms] = useState(
    []
  );
  const [allAvailableGames, setAllAvailableGames] = useState([]);
  const [games, setGames] = useState(null);
  const [platforms, setPlatforms] = useState(null);
  const [platformLoading, setPlatformLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState(null);
  const [searchData, setSearchData] = useState("");
  const [updateAllPlatForm, setUpdateAllPlatForm] = useState();
  const [updateSearchData, setUpdateSearchData] = useState();
  const [categoryAndGames, setCategoryAndGames] = useState({});
  const activeCatId =
    allCategories?.filter(
      (category) =>
        JSON.parse(category.name).en.toLowerCase() ===
        location.pathname.split("/")[1]
    )[0] || activeCatRedux;

  const [showPopup, setShowPopup] = useState(false);
  const [prevActiveCatId, setPrevActiveCatID] = useState(null);
  // const clientConfig = useSelector((state) => state.client.clientConfig);

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem("AgeVerification");

    if (!hasSeenPopup) {
      setShowPopup(true);
    }
  }, []);

  const hideModal = () => {
    setShowPopup(false);
    localStorage.setItem("AgeVerification", "true");
  };

  const GameProvidersFunc = async (
    activeCategoryId,
    selectedSubCat,
    searchGames
  ) => {
    setPlatformLoading(true);
    // setShowLoader(true);
    let currency_id =
      localStorage.getItem("currency_id") ?? clientConfig.currencyBackendCode;
    const activeCatGames = await getCatergoriesWithGames(
      activeCategoryId,
      currency_id,
      page,
      selectedSubCat,
      searchGames
    );
    setActiveCat(activeCatGames?.category);
    setCategoryAndGames(activeCatGames);
    setAllAvailableGames(activeCatGames?.gameItems);
    setPageData(activeCatGames?.gameItems);

    if (activeCatGames?.category?.child_categories?.length > 0) {
      setPlatforms();
    } else if (activeCatGames?.category?.is_lobby) {
      setPlatforms();
      setSelectedPlatforms([]);
    }

    setPlatformLoading(false);
    // setShowLoader(false);
  };

  const GameSubProvidersFunc = async (activeCategoryId) => {
    setPlatformLoading(true);
    if (activeCategoryId) {
      let currency_id =
        localStorage.getItem("currency_id") ?? clientConfig.currencyBackendCode;

      const platformResponse = await getGameProviders(
        activeCategoryId,
        currency_id
      );

      if (platformResponse.length) {
        setPlatforms(platformResponse);
      } else {
        setPlatforms();
      }
    }
    setPlatformLoading(false);
  };

  useEffect(() => {
    setShowLoader(true);
    setPage(1);
    if (activeCatId) {
      GameProvidersFunc(activeCatId?.id, selectedPlatforms, searchData);
    }
    if (!activeCatId?.is_lobby) {
      GameSubProvidersFunc(activeCatId?.id);
    }
    if (selectedPlatforms.length) {
      GameProvidersFunc(activeCatId?.id, searchData);
      setSelectedPlatforms([]);
    }
    if (searchData) {
      setSearchData("");
      GameProvidersFunc(activeCatId?.id);
    }
    setShowLoader(false);
  }, [activeCatId]);

  // useEffect(() => {
  //   if (activeCatId && selectedPlatforms?.length > 0) {
  //     // setSearchData("");
  //     // GameProvidersFunc(activeCatId?.id, selectedPlatforms, searchData);
  //   } else {
  //     // GameProvidersFunc(activeCatId?.id, selectedPlatforms, searchData);
  //     // setGames(allAvailableGames);
  //   }
  // }, [selectedPlatforms]);

  useEffect(() => {
    // setSearchData("");
    if (prevActiveCatId !== activeCatId) {
      setUpdateSearchData("");
    }
    setPrevActiveCatID(activeCatId);
    if (activeCatId && searchData !== "") {
      GameProvidersFunc(activeCatId?.id, selectedPlatforms, searchData);
    } else if (activeCatId && searchData === "") {
      GameProvidersFunc(activeCatId?.id, selectedPlatforms, searchData);
    }
  }, [updateSearchData]);

  useEffect(() => {
    if (activeCatId) {
      GameProvidersFunc(activeCatId?.id, selectedPlatforms, searchData);
    }
  }, [updateAllPlatForm, page]);

  const HandleSearchButton = async (searchData) => {
    setPage(1);
    setUpdateSearchData(searchData);
  };
  return (
    <>
      <div className="min-h-70% w-[100%]">
        {showPopup && (
          <AgeVerificationPopup show={showPopup} hideModal={hideModal} />
        )}
        {/* {activeCatId == null ? <Loader /> : null} */}
        <CategoryAndGameSection
          categoryAndGames={categoryAndGames}
          showLoader={showLoader}
          platforms={platforms}
          platformLoading={platformLoading}
          selectedPlatforms={selectedPlatforms}
          setSelectedPlatforms={setSelectedPlatforms}
          activeCat={activeCat}
          games={games}
          allAvailableGames={allAvailableGames}
          setGames={setGames}
          multipleSelectedPlatforms={multipleSelectedPlatforms}
          setMulitpleSelectedPlatforms={setMulitpleSelectedPlatforms}
          page={page}
          setPage={setPage}
          searchData={searchData}
          setSearchData={setSearchData}
          setUpdateAllPlatForm={setUpdateAllPlatForm}
          HandleSearchButton={HandleSearchButton}
          trendingGames={trendingGames}
          pageData={pageData}
          setAllAvailableGames={setAllAvailableGames}
        />
      </div>
    </>
  );
};

export default Home;
