import React, { useState } from "react";
import styles from "./Lobby.module.css";
import LoginPopupModal from "../../../../Helper/LoginPopupModal";
import { useSelector } from "react-redux";
import useLogout from "../../../../../layouts/Header/useLogout";
import useApiPlayGame from "../../../../../Helper/useApiPlayGame";
import MyLoader from "../../../../shared-components/Loader/MyLoader";
import { useTranslation } from "react-i18next";
import playIcon from "../../../../../assets/PlayNowButton.png";
import { useLocation, useNavigate } from "react-router-dom";
import SportsBgImage from "../../../../../assets/images/bg-games/Sports - BG@2x-min.png";
import CasinoBgImage from "../../../../../assets/images/bg-games/Casino - BG@2x-min.png";
import Banner from "../../../../shared-components/banner/Banner";
import SmallLoader from "../../../../shared-components/Loader/SmallLoader";

const Lobby = ({ games, categoryAndGames }) => {
  const { t } = useTranslation();
  const userReduxData = useSelector((state) => state?.user?.user);
  const [loadingGameId, setLoadingGameId] = useState(null);
  const [popupError, setPopupError] = useState("");
  const logout = useLogout();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { APIPlayGameFunc, isLoading } = useApiPlayGame(
    userReduxData,
    setLoadingGameId,
    setPopupError,
    logout
  );

  const bgImage = pathname === "/sport" ? SportsBgImage : CasinoBgImage;
  const lobbyInfo1 = categoryAndGames?.category?.properties_array?.find(
    (item) => item.lobby_layout_1
  );
  const lobbyInfo2 = categoryAndGames?.category?.properties_array?.find(
    (item) => item.lobby_layout_2
  );

  const handleGameClick = async (gameId) => {
    setLoadingGameId(gameId);
    const result = await APIPlayGameFunc(gameId);
    // if (result?.message === "SUCCESS") {
    //   setLoadingGameId(null); // Stop loader on SUCCESS
    // } else {
    //   setPopupError(
    //     result?.message || "An error occurred. Please try Again later"
    //   );
    //   setLoadingGameId(null); // Stop loader on error
    // }
  };

  const renderContent = (game, categoryAndGames) => {
    if (lobbyInfo1?.lobby_layout_1) {
      return (
        <div>
          <div className={styles.imageLayout1Container}>
            <div className={styles.imageOverlay}>
              <div className={styles.playButtonContainer}>
                {loadingGameId === game?.game_item_id ? (
                  <SmallLoader />
                ) : (
                  <img
                    src={playIcon}
                    alt="playIcon"
                    className={styles.imageItem}
                    onClick={() => handleGameClick(game?.game_item_id)}
                  />
                )}
              </div>
            </div>
            <img
              style={{ cursor: "pointer" }}
              loading="lazy"
              src={game?.icon_rectangle}
              alt={`game_image_${game?.game_item_id}`}
              onClick={() => handleGameClick(game?.game_item_id)}
            />
          </div>
        </div>
      );
    } else if (lobbyInfo2?.lobby_layout_2) {
      return (
        <>
          <div className={styles.imageContainer}>
            <div className={styles.imageOverlay}>
              <div className={styles.playButtonContainer}>
                {loadingGameId === game?.game_item_id ? (
                  <SmallLoader />
                ) : (
                  <img
                    src={playIcon}
                    alt="playIcon"
                    className={styles.imageItem}
                    onClick={() => handleGameClick(game?.game_item_id)}
                  />
                )}
              </div>
            </div>
            <img
              style={{ cursor: "pointer" }}
              loading="lazy"
              src={game?.icon_rectangle}
              alt={`game_image_${game?.game_item_id}`}
              onClick={() => handleGameClick(game?.game_item_id)}
            />
          </div>
        </>
      );
    } else {
      return (
        <div className={styles.flexBox1}>
          <div className={styles.flexBox}>
            <img
              className={styles.cockfightingImageItem}
              src={game?.icon_rectangle}
              alt={`game_image_${game?.game_item_id}`}
              onClick={() => handleGameClick(game?.game_item_id)}
            />
            {loadingGameId === game?.game_item_id ? (
              <div className={styles.cockfightingPlayButton}>
                <SmallLoader />
              </div>
            ) : (
              <>
                <img
                  src={playIcon}
                  alt="playIcon"
                  className={styles.cockfightingPlayButton}
                  onClick={() => handleGameClick(game?.game_item_id)}
                />
              </>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className={
        (lobbyInfo1 && styles.LobbyContainer) ||
        (lobbyInfo2 && styles.CasinoLobbyContainer) ||
        styles.cockfightingLobbyContainer
      }
    >
      {!lobbyInfo1?.lobby_layout_1 && !lobbyInfo2?.lobby_layout_2}

      <div
        className={
          lobbyInfo1
            ? styles.sportsLobbyWrapper
            : lobbyInfo2
            ? styles.casinoLobbyWrapper
            : styles.cockfightingLobbyWrapper
        }
      >
        {games?.map((game) => {
          return (
            <React.Fragment key={game?.game_item_id}>
              {renderContent(game, categoryAndGames)}
            </React.Fragment>
          );
        })}
      </div>
      {popupError && (
        <LoginPopupModal
          message={popupError}
          show={popupError ? true : false}
          hideModal={() => {
            setPopupError("");
            if (
              popupError ===
              "Player is not allowed to play games, Please contact the customer support to activate your account."
            ) {
              navigate("/support");
            }
          }}
        />
      )}
    </div>
  );
};

export default Lobby;
