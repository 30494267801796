import React from 'react'

const Custombutton = ({ text, type, onClick, className, disabled = false, htmlType =''}) => {

  return (
    <button onClick={onClick} className={`${type} ${className}`} disabled={disabled} htmlType={htmlType}>{text}</button>

  )
}

export default Custombutton