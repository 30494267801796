import React, { useEffect, useState } from "react";
import styles from "./Login.module.css";
import BW9Logo from "../../../assets/images/company-logo/bw9 logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputField from "../../shared-components/inputField/InputField";
import UsernameIcon from "../../../assets/Username.png";
import PasswordIcon from "../../../assets/PasswordIcon.png";
import EyeOpen from "../../../assets/EyeOpenIcon.png";
import Eye from "../../../assets/EyeClosedIcon.png";
import CustomButton from "../../shared-components/custom-button/customButton";
import Register from "../Register/Register";
import { APILoginUser } from "../../../Api/Apis";
import { setUser } from "../../../redux/Slice/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import SmallLoader from "../../shared-components/Loader/SmallLoader";
import LoginPopupModal from "../../Helper/LoginPopupModal";
import AppHeader from "../../../layouts/Header/AppHeader";
import loginGameImage from "../../../assets/LOGIN.webp";
import { useTranslation } from "react-i18next";
import FacebookIcon from "../../../assets/images/social-media/Facebook@2x.png";
import BackButton from "../../../assets/images/back-icon/Active Home.png";
import { DeviceAndBrowserInfo } from "./DeviceAndBrowserInfo";

const inputBox = {
  maxWidth: "521px",
  width: "100%",
  height: "43px",
  background: "#000326 0% 0% no-repeat padding-box",
  border: "none",
  borderRadius: "7px",
  opacity: 1,
  textAlign: "left",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontSize: "16px",
  lineHeight: "19px",
  fontFamily: "Roboto",
  letterSpacing: 0,
  color: "#FFFFFF",
};

const Login = () => {
  const urlLocation = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [popupError, setPopupError] = useState("");
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.user.user);
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });
  const [toggleButton, setToggleButton] = useState({
    login: true,
    signup: false,
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (urlLocation.pathname?.toLowerCase() === "/register") {
      setToggleButton({
        login: false,
        signup: true,
      });
    }
    if (urlLocation.pathname?.toLowerCase() === "/login") {
      setToggleButton({
        login: true,
        signup: false,
      });
    }
  }, [urlLocation]);

  const handleLoginInputChange = (name, value) => {
    setLoginFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onFinish = async (e) => {
    e.preventDefault();

    if (loginFormData?.email && loginFormData?.password) {
      setLoading(true);
      const info = DeviceAndBrowserInfo();
      // console.log(info);
      const res = await APILoginUser(
        loginFormData?.email,
        loginFormData?.password,
        info.deviceType,
        info.browserName
      );

      if (res?.status === 200 && res?.data?.token) {
        localStorage.setItem("auth_token", res?.data?.token);
        localStorage.setItem("currency_id", res?.data?.data?.currency_id);

        dispatch(setUser(res?.data?.token));
        navigate("/popular");
      } else if (res.data.message === "LOGIN_ATTEMPTS_EXCEEDED") {
        setErrorMessage(t("LOGIN_LIMIT_EXCEEDED"));
      } else if (res.data.message === "USERNAME_OR_PASSWORD_INCORRECT") {
        setErrorMessage(t("USER_DOES_NOT_EXIST"));
      } else if (res.data.message === "ACCOUNT_INACTIVE") {
        setPopupError(t("ACCOUNT_INACTIVE"));
      } else if (res.data.message === "PASSWORD_INCORRECT") {
        setErrorMessage(t("PASSWORD_INCORRECT"));
      } else if (res.status === 500) {
        setErrorMessage(t("ERROR_500"));
      } else {
        setErrorMessage(t("DOES_NOT_MATCH"));
      }
    }
    setLoading(false);
  };

  const checkValidation = () =>
    loginFormData?.email !== "" && loginFormData?.password !== "";

  return (
    <div style={{ position: "relative" }}>
      <AppHeader />
      <div className={`${styles.loginWrapper}`}>
        <div className={styles.loginSubContainer}>
          <img
            src={loginGameImage}
            alt="gameImge"
            className={styles.loginContainerImg}
          />

          <div className={styles.backButton}>
            <img
              src={BackButton}
              style={{ cursor: "pointer" }}
              className="imgStyle"
              onClick={() => (userToken ? navigate(-1) : navigate("/"))}
              alt="back button"
            />
          </div>

          <div className={styles.buttonWrapper}>
            <div className={styles.dontHaveAcStyle}>
              {toggleButton?.login
                ? t(`Don't have an account?`)
                : t(`Already have an account?`)}
            </div>
            {toggleButton?.login ? (
              <div
                className={styles.btnContainer}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setToggleButton({ login: false, signup: true });
                  navigate("/register");
                }}
              >
                <button className={styles.btnStyling}>{t(`signUp`)}</button>
              </div>
            ) : (
              <div
                className={styles.btnContainer}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setToggleButton({ login: true, signup: false });
                  navigate("/login");
                }}
              >
                <button className={styles.btnStyling}>{t("Login")}</button>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
              margin: "auto",
              maxWidth: "500px",
              width: "100%",
              padding: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div className={styles.titleLabel}>
                {toggleButton?.login ? t("Login") : t("signUp")}
              </div>
              <div
                style={{
                  marginBottom: toggleButton?.signup ? "20px" : "",
                }}
                className={styles.titleSloganText}
              >
                {toggleButton?.login
                  ? t("Access your account")
                  : t("Register your account")}
              </div>
            </div>

            {toggleButton.login && (
              <>
                <form
                  style={{
                    width: "100%",
                    marginTop: toggleButton?.login ? "20px" : "",
                  }}
                  onSubmit={onFinish}
                >
                  <div
                    className={` ${styles.formContainer}`}
                    style={{ gap: toggleButton?.login ? 18 : "" }}
                  >
                    <InputField
                      placeHolder={t("Your username")}
                      extraStyle={inputBox}
                      cls={styles.loginInputClass}
                      type="text"
                      name="email"
                      prefix={
                        <div style={{ width: 20, height: 20 }}>
                          <img
                            src={UsernameIcon}
                            style={{
                              width: "100%",
                              height: "100%",
                              opacity: 1,
                              marginRight: 5,
                              objectFit: "contain",
                            }}
                            preview={false}
                            alt="usernameIcon"
                          />
                        </div>
                      }
                      value={loginFormData.email}
                      onChange={handleLoginInputChange}
                      required={true}
                    />
                    <InputField
                      placeHolder={t("Your password")}
                      extraStyle={inputBox}
                      cls={styles.loginInputClass}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      required={true}
                      prefix={
                        <div style={{ width: 20, height: 20 }}>
                          <img
                            src={PasswordIcon}
                            style={{
                              width: "100%",
                              height: "100%",
                              opacity: 1,
                              marginRight: "5px",
                              objectFit: "contain",
                            }}
                            preview={false}
                            alt="icon"
                          />
                        </div>
                      }
                      suffix={
                        showPassword ? (
                          <img
                            src={EyeOpen}
                            style={{
                              width: 25,
                              height: 25,
                              opacity: 1,
                              objectFit: "contain",
                            }}
                            onClick={() => setShowPassword(!showPassword)}
                            preview={false}
                            alt="icon"
                          />
                        ) : (
                          <img
                            src={Eye}
                            style={{
                              width: 25,
                              height: 25,
                              opacity: 1,
                              objectFit: "contain",
                            }}
                            onClick={() => setShowPassword(!showPassword)}
                            preview={false}
                            alt="icon"
                          />
                        )
                      }
                      value={loginFormData.password}
                      onChange={handleLoginInputChange}
                    />

                    <Link to="/support">
                      <div
                        className={`${styles.forgetPasswordText} mt-1  flex justify-end items-end`}
                      >
                        {t("Forgot Password?")}
                      </div>
                    </Link>

                    {errorMessage && (
                      <span className={styles.errorMsg}>{errorMessage}</span>
                    )}

                    {loading ? (
                      <SmallLoader height="36px" />
                    ) : (
                      <button
                        type="submit"
                        className={`${styles.loginBtnn} ${
                          checkValidation()
                            ? styles.activeButton
                            : styles.disabled
                        }`}
                      >
                        {t("Login")}
                      </button>
                    )}
                  </div>
                </form>

                {/* <p className={styles.orText}>Or</p> */}

                {/* <button
                  className={`${styles.facebookButton} flex items-center justify-center gap-2`}
                >
                  <img
                    className="w-6 h-6"
                    src={FacebookIcon}
                    alt={`facebook icon`}
                  />

                  {t("Login with Facebook")}
                </button> */}

                <p
                  style={{ marginTop: "15px" }}
                  className={styles.termsTextStyle}
                >
                  {t(
                    "By accessing you confirm that you are at least 18 years old and agree to the"
                  )}{" "}
                  <Link
                    to={"/terms-and-conditions"}
                    className={styles.termsServiceLink}
                  >
                    {t("Terms and service")}
                  </Link>
                </p>
              </>
            )}

            {toggleButton.signup && <Register />}
          </div>
        </div>
        {popupError && (
          <LoginPopupModal
            message={popupError}
            show={!!popupError}
            errorIcon={true}
            hideModal={() => {
              navigate("/");
              setPopupError("");
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Login;
