import React from "react";

const InputField = ({
  placeHolder,
  type,
  cls,
  name,
  value,
  onChange,
  extraStyle,
  suffix,
  prefix,
  required,
  onBlur,
  onKeyPress,
}) => {
  const handleFieldChange = (event) => {
    const { value } = event.target;
    onChange(name, value, event.target);
  };
  const inputStyle = {
    width: "190px",
    height: "35px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #D9D9D9",
    borderRadius: "3px",
    ...extraStyle,
  };
  return (
    <div className={`relative ${cls}`}>
      {prefix && (
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          {prefix}
        </span>
      )}
      <input
        style={{ ...inputStyle }}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        required={required}
        className={` ${suffix ? "pl-10 " : `${name==="phone" ? "pl-[5.5rem]" : "pl-10"}`}`} 
        type={type}
        placeholder={placeHolder}
        name={name}
        value={value}
        onChange={handleFieldChange}
        maxLength={name === "email" ? 200 : ''}
      />
      {suffix && (
        <span className="absolute inset-y-0 top-0 right-0 flex items-center pr-2">
          {suffix}
        </span>
      )}
    </div>
  );
};

export default InputField;
