import React, { useState } from 'react';
import styles from './QuestPopup.module.css';
import bigWin from '../../../assets/quest hub/Big Win@2x.png';
import completedIcon from '../../../assets/quest hub/Completed@2x.png';
import infoIcon from '../../../assets/quest hub/Info@2x.png';
import noDataFoundIcon from '../../../assets/quest hub/No Data@2x.png';

const PreviousQuest = () => {

    const [isClaimed, setIsClaimed] = useState(false);

    return (
        <div className={styles.detailSection}>
            <div className='flex items-center justify-between'>
                <h2 className={styles.daysStyling}>Wednesday</h2>
                <h2 className={styles.dateStyling}>14/04/2024 - 28/04/2024</h2>
            </div>
            <div className={styles.claimSection}>
                <div className='flex flex-col gap-1'>
                    <div className={styles.rewardTitle}>Unclaimed</div>
                    <div className={styles.dateDetails}>Wed, 24/04</div>
                </div>
                <div className='flex items-center gap-3'>
                    <div className={styles.dateDetails}>Expires in</div>
                    <div className={styles.rewardTitle}>2D 15 : 47 : 12</div>
                </div>
            </div>
            {!isClaimed ?
                <div className="w-full flex bg-[#24224A] rounded-[10px] p-3 gap-3 items-center justify-around">
                    <div className="max-w-[70px] w-full max-h-[70px] h-full">
                        <img src={bigWin} alt="bigwin" className='imgStyle' />
                    </div>
                    <div className="flex flex-col">
                        <div className='flex items-center gap-4'>
                            <div className="text-left not-italic font-normal text-[14px] tracking-normal leading-5 font-Poppins text-[#FFFFFF] opacity-100">
                                Welcome Bonuses
                            </div>
                            <div className='flex items-center gap-1'>
                                <div className='max-w-[14px] w-full max-h-[14px] h-full'>
                                    <img src={completedIcon} alt='completed' className='imgStyle' />
                                </div>
                                <div className='text-left not-italic font-normal text-[11px] leading-4 font-Poppins tracking-normal text-[#19CD79] opacity-100'>Completed</div>
                            </div>
                        </div>
                        <p className="text-left not-italic font-normal text-[11px] tracking-normal leading-4 font-Poppins text-[#7473B7] opacity-100">
                            Welcome Bonuses
                        </p>
                        <div className="text-left italic font-semibold text-[16px] leading-6 font-Poppins tracking-normal text-[#FFD029] opacity-100">
                            Earn ₱ 5
                        </div>
                    </div>
                    {isClaimed ? <div className={styles.claimedtextStyle}>Claimed</div> : <div className={styles.claimBtn} onClick={() => setIsClaimed(true)}>Claim</div> }
                </div>:
                <div className='flex flex-col items-center'>
                <div className='max-w-[140px] w-full max-h-[140px] h-full'>
                    <img src={noDataFoundIcon} alt='nodata' className='imgStyle' />
                </div>
                <div className={styles.noDataText}>Oops! There is no data yet!</div>
            </div>

            }

            <div className='flex flex-col gap-4'>
                <div className='flex items-center justify-between'>
                    <div className={styles.rewardTitle}>Unfinished Quests</div>
                    <div className='flex items-center gap-3'>
                        <div className='max-w-[13px] w-full max-h-[13px] h-full'><img src={infoIcon} alt='info' className='imgStyle' /></div>
                        <div className={styles.dateStyling}>Only the latest week shown</div>
                    </div>
                </div>

                <div className='flex flex-col items-center'>
                    <div className='max-w-[140px] w-full max-h-[140px] h-full'>
                        <img src={noDataFoundIcon} alt='nodata' className='imgStyle' />
                    </div>
                    <div className={styles.noDataText}>Oops! There is no data yet!</div>
                </div>
            </div>

            <div className='flex flex-col gap-4'>
                <div className={styles.rewardTitle}>Claimed</div>
                {isClaimed ?
                    <div className="w-full flex bg-[#24224A] rounded-[10px] p-3 gap-3 items-center justify-around">
                        <div className="max-w-[70px] w-full max-h-[70px] h-full">
                            <img src={bigWin} alt="bigwin" className='imgStyle' />
                        </div>
                        <div className="flex flex-col">
                            <div className='flex items-center gap-4'>
                                <div className="text-left not-italic font-normal text-[14px] tracking-normal leading-5 font-Poppins text-[#FFFFFF] opacity-100">
                                    Welcome Bonuses
                                </div>
                                <div className='flex items-center gap-1'>
                                    <div className='max-w-[14px] w-full max-h-[14px] h-full'>
                                        <img src={completedIcon} alt='completed' className='imgStyle' />
                                    </div>
                                    <div className='text-left not-italic font-normal text-[11px] leading-4 font-Poppins tracking-normal text-[#19CD79] opacity-100'>Completed</div>
                                </div>
                            </div>
                            <p className="text-left not-italic font-normal text-[11px] tracking-normal leading-4 font-Poppins text-[#7473B7] opacity-100">
                                Welcome Bonuses
                            </p>
                            <div className="text-left italic font-semibold text-[16px] leading-6 font-Poppins tracking-normal text-[#FFD029] opacity-100">
                                Earn ₱ 5
                            </div>
                        </div>
                        {isClaimed ? <div className={styles.claimedtextStyle}>Claimed</div> : <div className={styles.claimBtn} onClick={() => setIsClaimed(true)}>Claim</div> }
                        
                    </div> :

                    <div className='flex flex-col items-center'>
                        <div className='max-w-[140px] w-full max-h-[140px] h-full'>
                            <img src={noDataFoundIcon} alt='nodata' className='imgStyle' />
                        </div>
                        <div className={styles.noDataText}>Oops! There is no data yet!</div>
                    </div>
                }
            </div>
        </div>
    )
}

export default PreviousQuest
