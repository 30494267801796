import React, { useState, useEffect } from "react";
import CategoryComponent from "./CategoryComponent";
import styles from "./CategoryAndGameSection.module.css";
import Lobby from "./Lobby/Lobby";
import Filter from "./Filter/Filter";
import SearchBox from "./SearchBox";
import useApiPlayGame from "../../../../Helper/useApiPlayGame";
import { useSelector } from "react-redux";
import useLogout from "../../../../layouts/Header/useLogout";
import LoginPopupModal from "../../../Helper/LoginPopupModal";
import MyLoader from "../../../shared-components/Loader/MyLoader";
import nextIcon from "../../../../assets/images/swip/Right Swip.png";
import preIcon from "../../../../assets/images/swip/Left Swip.png";
import playIcon from "../../../../assets/PlayNowButton.png";
import NoImageIcon from "../../../../assets/images/NoImageAvailabel/NoImage2.jpg";
import { Col, Pagination, Row } from "antd";
import Loader from "../../../shared-components/Loader/Loader";
import NoDataComp from "../../../shared-components/NoData/NoDataComp";
import { useTranslation } from "react-i18next";
import Platforms from "../platforms/Platforms";
import Banner from "../../../shared-components/banner/Banner";
import { useLocation, useNavigate } from "react-router-dom";
import SmallLoader from "../../../shared-components/Loader/SmallLoader";
import MainPageLoading from "../../../shared-components/MainPageLoading/MainPageLoading"; // import MainPageLoading

const CategoryAndGameSection = ({
  categoryAndGames,
  platformLoading,
  platforms,
  selectedPlatforms,
  setSelectedPlatforms,
  activeCat,
  games,
  allAvailableGames,
  setGames,
  showLoader,
  multipleSelectedPlatforms,
  setMulitpleSelectedPlatforms,
  page,
  setPage,
  setSearchData,
  searchData,
  setUpdateAllPlatForm,
  HandleSearchButton,
  trendingGames,
  pageData,
  setAllAvailableGames,
}) => {
  const { t } = useTranslation();
  const userReduxData = useSelector((state) => state?.user?.user);
  const [loadingGameId, setLoadingGameId] = useState(null);
  const [popupError, setPopupError] = useState("");
  const logout = useLogout();
  const navigate = useNavigate();
  const { APIPlayGameFunc, isLoading } = useApiPlayGame(
    userReduxData,
    setLoadingGameId,
    setPopupError,
    logout
  );
  const location = useLocation();
  const { pathname } = location;
  const [isContentLoading, setIsContentLoading] = useState(true);
  const [tempCategoryAndGames, setTempCategoryAndGames] = useState(null);
  // const [tempAllAvailableGames, setTempAllAvailableGames] = useState(null);

  // New state for controlling the loader visibility
  // const [showMainPageLoading, setShowMainPageLoading] = useState(false);

  useEffect(() => {
    // Clear the data when switching pages
    setIsContentLoading(true);
    setTempCategoryAndGames(null);
    // setTempAllAvailableGames(null);
  }, [pathname]);

  useEffect(() => {
    // setIsContentLoading(true);
    // setTempAllAvailableGames(null);
  }, [page]);

  useEffect(() => {
    // Once the new data is loaded, set the content loading state to false
    if (categoryAndGames) {
      setTempCategoryAndGames(categoryAndGames);
      // setTempAllAvailableGames(allAvailableGames);
      setIsContentLoading(false);
    }
  }, [categoryAndGames]);

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <div
          style={{ maxWidth: 9, width: "100%", maxHeight: 14, height: "100%" }}
        >
          <img src={preIcon} className="imgStyle" alt="preimg" />
        </div>
      );
    }
    if (type === "next") {
      return (
        <div
          style={{ maxWidth: 9, width: "100%", maxHeight: 14, height: "100%" }}
        >
          <img src={nextIcon} className="imgStyle" alt="nextimg" />
        </div>
      );
    }

    return originalElement;
  };

  if (isContentLoading) {
    return <SmallLoader type="categoryGamepage" />;
  }

  return (
    <div className={`${styles.gamesWrapper}`}>
      {showLoader ? (
        <SmallLoader />
      ) : (
        <>
          {tempCategoryAndGames?.category?.child_categories?.length > 0 ? (
            <div className="px-[20px] mb-10">
              {platformLoading ? (
                <SmallLoader />
              ) : (
                <>
                  <Banner />
                  <CategoryComponent
                    category={tempCategoryAndGames?.category?.child_categories}
                    APIPlayGameFunc={APIPlayGameFunc}
                    trendingGames={trendingGames}
                  />
                </>
              )}
            </div>
          ) : tempCategoryAndGames?.category?.is_lobby &&
            tempCategoryAndGames?.gameItems?.data?.length > 0 ? (
            platformLoading ? (
              <SmallLoader />
            ) : (
              <Lobby
                games={tempCategoryAndGames?.gameItems?.data}
                categoryAndGames={tempCategoryAndGames}
                APIPlayGameFunc={APIPlayGameFunc}
              />
            )
          ) : (
            <div
              className={`${
                (pathname === "/slots" && `${styles.slotsContainer}`) ||
                (pathname === "/fishing" && `${styles.fishingContainer}`)
              }
         !p-[10px] !pt-[100px]`}
            >
              {platforms?.length > 0 &&
                !tempCategoryAndGames?.category?.is_lobby && (
                  <div className={styles.platformMainContainer}>
                    <Platforms
                      setUpdateAllPlatForm={setUpdateAllPlatForm}
                      setSearchData={setSearchData}
                      setGames={setGames}
                      setPage={setPage}
                      platforms={platforms}
                      selectedPlatforms={selectedPlatforms}
                      setSelectedPlatforms={setSelectedPlatforms}
                      setMulitpleSelectedPlatforms={
                        setMulitpleSelectedPlatforms
                      }
                      setAllAvailableGames={setAllAvailableGames}
                      // setTempAllAvailableGames={setTempAllAvailableGames}
                      setTempCategoryAndGames={setTempCategoryAndGames}
                    />

                    <SearchBox
                      platforms={platforms}
                      multipleSelectedPlatforms={multipleSelectedPlatforms}
                      setMulitpleSelectedPlatforms={
                        setMulitpleSelectedPlatforms
                      }
                      selectedPlatforms={selectedPlatforms}
                      setSelectedPlatforms={setSelectedPlatforms}
                      setSearchData={setSearchData}
                      setGames={setGames}
                      setUpdateAllPlatForm={setUpdateAllPlatForm}
                      HandleSearchButton={HandleSearchButton}
                      searchData={searchData}
                    />
                  </div>
                )}

              {!categoryAndGames || platformLoading ? (
                <div className="h-[60vh] flex justify-center items-center">
                  <SmallLoader />
                </div>
              ) : (
                <div className={styles.platformContentContainer}>
                  {platformLoading ||
                  platforms === 0 ||
                  tempCategoryAndGames?.gameItems?.data?.length === 0 ||
                  tempCategoryAndGames?.category?.is_lobby ? (
                    <div className="h-[65vh]">
                      <NoDataComp />
                    </div>
                  ) : (
                    <>
                      <div
                        className={`pt-4 flex flex-wrap gap-3 xl:gap-4 ${
                          tempCategoryAndGames?.gameItems?.data?.length > 5
                            ? "justify-center"
                            : " ml-5 justify-start"
                        }  `}
                      >
                        {tempCategoryAndGames?.gameItems?.data?.length &&
                          tempCategoryAndGames?.gameItems?.data?.map((game) => (
                            <div key={game.id}>
                              {game.id === loadingGameId ? (
                                <div
                                  className={styles.imageStyling}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <SmallLoader />
                                </div>
                              ) : (
                                <div className={`${styles.imageContainer} `}>
                                  <div className={styles.imageOverlay}>
                                    <div className={styles.playButtonContainer}>
                                      <img
                                        src={playIcon}
                                        alt="playIcon"
                                        className="imgStyle"
                                        onClick={() =>
                                          APIPlayGameFunc(game.game_item_id)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <img
                                    style={{ cursor: "pointer" }}
                                    loading="lazy"
                                    src={game?.icon_square}
                                    alt={`Img${game.id}`}
                                    className={styles.imageStyling}
                                    onClick={() =>
                                      APIPlayGameFunc(game.game_item_id)
                                    }
                                    onError={(e) =>
                                      (e.target.src = NoImageIcon)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                      </div>

                      {pageData?.per_page < pageData?.total ? (
                        <div className={styles.paginationWrapper}>
                          <Pagination
                            total={pageData?.total}
                            current={pageData?.current_page}
                            defaultCurrent={page}
                            showSizeChanger={false}
                            onChange={setPage}
                            pageSize={pageData?.per_page}
                            itemRender={itemRender}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              )}

              {popupError && (
                <LoginPopupModal
                  message={popupError}
                  show={popupError ? true : false}
                  hideModal={() => {
                    setPopupError("");
                    if (
                      popupError ===
                      "Player is not allowed to play games, Please contact the customer support to activate your account."
                    ) {
                      navigate("/support");
                    }
                  }}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CategoryAndGameSection;
