import React from 'react';
import styles from './MainPageLoading.module.css';
import Logo from '../../../assets/LOGO BW9@2x.png';
import LoadingIcon from '../../../assets/Loading@2x.png';
import { Image, Typography } from 'antd';

const MainPageLoading = () => {
    return (
        <div className={`${styles.modalOverlay}`}>
            <div className={styles.loadingSectionWrapper}>
            <div className={styles.loadingSubContainer}>
                <div className={styles.imgWrapper}>
                    <img src={Logo} alt='logo' className='imgStyle' />
                </div>
                <div className={styles.lodingTitle}>BEST WAY TO WIN!</div>

                <div className={styles.loadingSpinIcon}>
                <Image
                  className="animate-spin"
                  preview={false}
                  src={LoadingIcon}
                  alt="Loading"
                  width="25px"
                  height="25px"
                />
                <Typography.Text className={styles.LoadingText}>
                  Loading
                </Typography.Text>
              </div>

            </div>
            </div>
        </div>
    )
}

export default MainPageLoading
