import React from "react";
import styles from "./NoDataComp.module.css";
import noDataFoundIcon from "../../../assets/No Data Icon.png";

const NoDataComp = (props) => {
  const { message, color } = props;

  return (
    <div className={styles.noDataIconContainer}>
      <div className={styles.noDataFoundContainer}>
        <div className={styles.imageContainer}>
          <img src={noDataFoundIcon} alt="no-data-found" className="imgStyle" />
        </div>
        <p
          style={{ color: color ? color : "#fff" }}
          className={`noDataFoundText  text-white`}
        >
          {message ? message : "No Data Found !"}
        </p>
      </div>
    </div>
  );
};

export default NoDataComp;
