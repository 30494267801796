import { FaTimes } from "react-icons/fa";
import styles from "./ShareToFriendsModal.module.css";
import { useTranslation } from "react-i18next";
import FacebookIcon from "../../../assets/images/social-media/ShareFacebookIcon.png";
import InstagramIcon from "../../../assets/images/social-media/ShareInstagramIcon.png";
import TelegramIcon from "../../../assets/images/social-media/ShareTelegramIcon.png";
import WhatsappIcon from "../../../assets/images/social-media/ShareWhatsAppIcon.png";

const ShareToFriendsModal = ({ logoutModal, closeLogoutModal, shareUrl }) => {
  const { t } = useTranslation();

  const socialMediaLinks = [
    {
      text: "Facebook",
      icon: FacebookIcon,
      link: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
    },
    {
      text: "Instagram",
      icon: InstagramIcon,
      link: `https://www.instagram.com/?url=${shareUrl}`,
    },
    {
      text: "Telegram",
      icon: TelegramIcon,
      link: `https://telegram.me/share/url?url=${shareUrl}`,
    },
    {
      text: "Whatsapp",
      icon: WhatsappIcon,
      link: `https://wa.me/?text=${shareUrl}`,
    },
  ];

  return (
    <div className={`${styles.modalOverlay} fixed z-50 inset-0 `}>
      <div className="flex items-center justify-center min-h-screen">
        <div className={styles.logoutOverlay}>
          <div
            style={{
              background: "#232244 0% 0% no-repeat padding-box",
            }}
            className="modal-header flex items-center justify-between p-6 rounded-t-[20px]"
          >
            <h5 className={styles.shareViaText}>Share Via</h5>

            <div>
              <FaTimes
                size={20}
                className=" top-4 right-4 cursor-pointer text-white"
                onClick={closeLogoutModal}
              />
            </div>
          </div>

          <div className="flex justify-center mt-4 space-x-8 px-6 pb-6 ">
            {socialMediaLinks.map((item, index) => (
              <div key={index}>
                <a
                  className="items-center flex flex-col gap-2 justify-center  cursor-pointer"
                  target="_blank"
                  title={item.text}
                  rel="noopener noreferrer"
                  href={item.link}
                  aria-label={`Share on ${item.text}`}
                >
                  <img
                    className="w-10 h-10 items-center"
                    src={item.icon}
                    alt={`${item.text} icon`}
                  />
                  <p className={styles.IconsTextStyle}>{item.text}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareToFriendsModal;
