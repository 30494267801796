import React, { useState } from "react";
import styles from "./Records.module.css";
import { Pagination, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import NoDataComp from "../../../shared-components/NoData/NoDataComp";
import { CommaSeperator, toFixedFunc } from "../../../Helper/HelperFunction";
import SmallLoader from "../../../shared-components/Loader/SmallLoader";
import preIcon from "../../../../assets/images/swip/Left Swip.png";
import nextIcon from "../../../../assets/images/swip/Right Swip.png";

const Records = ({ ReferRecords, loading, pageData, setPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <div
          style={{ maxWidth: 9, width: "100%", maxHeight: 14, height: "100%" }}
        >
          <img src={preIcon} className="imgStyle" alt="preimg" />
        </div>
      );
    }
    if (type === "next") {
      return (
        <div
          style={{ maxWidth: 9, width: "100%", maxHeight: 14, height: "100%" }}
        >
          <img src={nextIcon} className="imgStyle" alt="nextimg" />
        </div>
      );
    }

    return originalElement;
  };
  return (
    <div>
      {/* Table */}
      <div className={`relative mt-10 overflow-x-auto shadow-md sm:rounded-lg`}>
        <table
          className={`w-full text-sm text-left rtl:text-right table-fixed  ${styles.tableData}`}
        >
          <thead className={`text-sm bg-table  ${styles.tableHeader}`}>
            <tr>
              <th
                style={{ width: "200px" }}
                scope="col"
                className="px-6 py-3 font-normal"
              >
                Registration Date
              </th>
              <th
                style={{ width: "200px" }}
                scope="col"
                className="px-6 py-3 font-normal"
              >
                Username
              </th>
              <th
                style={{ width: "200px" }}
                scope="col"
                className="px-6 py-3 font-normal"
              >
                Amount
              </th>
              <th
                style={{ width: "200px" }}
                scope="col"
                className="px-6 py-3 font-normal"
              >
                Invite Status
              </th>
              {/* <th scope="col" className="px-6 py-3 font-normal"></th> */}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <SmallLoader />
            ) : ReferRecords?.length ? (
              ReferRecords?.map((item) => {
                let date = new Date(item?.created_at);
                const formattedDate = date.toLocaleString();
                const [datePart, timePart] = formattedDate.split(", ");

                return (
                  <tr
                    key={item.id}
                    className="border-gray-700 bg-body"
                    // onClick={() => navigate("/transections/" + item?.id)}
                  >
                    <td className={styles.tableData}>
                      {timePart} {datePart}
                    </td>
                    <td className={styles.tableData}>
                      <div>{item.user_name}</div>
                    </td>
                    <td className={styles.tableData}>
                      <div>{CommaSeperator(toFixedFunc(item.amount))}</div>
                    </td>
                    <td className="px-6 py-4 font-normal text-white whitespace-nowrap">
                      <div
                        className={`border rounded-[25px] w-fit flex items-center justify-center min-w-[70px] h-[25px] lg:min-w-[100px] lg:h-[30px] lg:text-[14px] ${
                          item.bonus_status
                            ? "border-[#00FFB5] bg-[#004430]"
                            : "border-[#BE8AFF] bg-[#14132D]"
                        } `}
                      >
                        <Typography.Text
                          className={`${styles.itemStatusText} text-[10px] lg:text-[12px]`}
                          style={{
                            //
                            color: item.bonus_status ? "#00FFB5" : "#BE8AFF",
                          }}
                        >
                          {item.bonus_status ? t(`Approved`) : t(`Pending`)}
                        </Typography.Text>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3" className="px-6 py-4 text-center text-gray-400">
                  <NoDataComp />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {ReferRecords?.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "20px 0",
            marginBottom: "100px",
          }}
        >
          <Pagination
            className="custom-pagination"
            current={pageData?.current_page}
            pageSize={10}
            total={pageData?.total}
            onChange={setPage}
            showSizeChanger={false}
            itemRender={itemRender}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          />
        </div>
      )}

      {/* {loading ? (
      <SmallLoader />
    ) : (
      <>
        {showViewMore && allTransactions?.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              margin: "20px 0",
              marginBottom: "100px",
            }}
          >
            <Pagination
              className="custom-pagination"
              current={pageData?.current_page}
              pageSize={10}
              total={pageData?.total}
              onChange={setPage}
              showSizeChanger={false}
              itemRender={itemRender}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            />
          </div>
        )}
      </>
    )} */}
    </div>
  );
};

export default Records;
