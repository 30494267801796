import React, { useEffect, useState } from "react";
import styles from "./EwalletManagement.module.css";
import { useNavigate } from "react-router-dom";
import { userPaymentMethodList } from "../../../../Api/BankApi";
import useLogout from "../../../../layouts/Header/useLogout";
import Loader from "../../../shared-components/Loader/Loader";
import { useTranslation } from "react-i18next";
import addEWallet from "../../../../assets/images/payment icons/Add E-Wallet.png";
import NoDataComp from "../../../shared-components/NoData/NoDataComp";
import SmallLoader from "../../../shared-components/Loader/SmallLoader";
import { Link } from "react-router-dom";
import backimage from "../../../../assets/images/back-icon/BackIcon.png";
import AddPaymentMethod from "../../AddPaymentMethod/AddPaymentMethod";
import gCashImg from "../../../../assets/images/payment icons/GCash.png";
import mayaImg from "../../../../assets/images/payment icons/maya.png";
import editIcon from "../../../../assets/Edit.png";

const EwalletManagement = () => {
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [loader, setLoader] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [clearFields, setClearFields] = useState(false);
  const [buttonText, setButtonText] = useState("Create");

  const navigate = useNavigate();
  const logout = useLogout();
  const { t } = useTranslation();

  const addWallet = () => {
    if (paymentMethods?.length < 3 && !loader) {
      navigate("/account/ewallet");
      setClearFields(true); // Set clearFields to true when adding a new wallet
      setButtonText(t("Create")); // Set button text to Create
    }
  };

  const getBankListFunc = async () => {
    setLoader(true);
    const res = await userPaymentMethodList();
    if (res?.data?.length === 0 || res?.data == null) {
      // setErrorMessage(t("bankErrMessage"));
      setPaymentMethods([]);
    } else if (res?.response?.status === 401) {
      logout();
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else {
      setPaymentMethods(res.data);
    }
    setLoader(false);
  };
  useEffect(() => {
    getBankListFunc();
  }, []);

  const handleEditClick = (wallet) => {
    // setSelectedWallet(wallet);
    // setButtonText("Update");  //Set the Button text as Update
  };

  return (
    <div className={`p-7 h-[100%] mb-10`}>
      <div className="flex mt-4 space-x-5 justify-normal items-center">
        <Link to="/account">
          <img
            src={backimage}
            alt="back"
            style={{ width: "13px", height: "21px" }}
          />
        </Link>
        <h2 className={styles.headTitleDesign}>{t(`E-wallet Management`)}</h2>
      </div>
      <div className={` ${styles.container} pb-7`}>
        <div className={`overflow-y-disable ${styles.ewalletContainer}`}>
          <div
            style={{
              borderLeft: "4px solid #5859F2",
              maxHeight: "30px",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            <p
              style={{
                marginLeft: "15px",
                // minWidth :"120px",
                textAlign: "left",
                font: "normal normal normal 20px Poppins",
                fontWeight: "400",
                letterSpacing: "0px",
                color: "#FFFFFF",
                opacity: 1,
              }}
            >
              {t(`Registered E-Wallet`)}
            </p>
          </div>
          {!paymentMethods ? (
            <div style={{ textAlign: "center" }}>
              <SmallLoader />
            </div>
          ) : paymentMethods?.length ? (
            paymentMethods?.map((item) => {
              return (
                <div className={`ml-1 ${styles.gCashContainer}`}>
                  {/* <div className={styles.editIconContainer} onClick={ ()=>  handleEditClick(item)}>
                  <img
                    src={editIcon}
                    className={styles.editIcon}
                    alt="editIcon"
                  />
                </div> */}
                  <p className={styles.holdersNameStyling}>
                    {item?.bank_code?.public_name?.en_public_name == "Gcash" ? (
                      <div className="flex items-center">
                        <img
                          src={gCashImg}
                          alt="GCash"
                          width="30"
                          height="30"
                        />
                        <h2 className="ml-2">GCash</h2>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <img src={mayaImg} alt="Maya" width="30" height="30" />
                        <h2 className="ml-2">Maya</h2>
                      </div>
                    )}
                  </p>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {/* <div style={{ width: 24, height: 20, }}>
                                <img src={Glogo} style={{ width: "100%", height: "100%", opacity: 1, objectFit: 'contain' }} alt='logo' />
                            </div> */}
                    <div
                    // style={{ width: 69, height: 16 }}
                    >
                      <p className={styles.holdersNameStyling}>
                        {item?.account_name}
                      </p>
                      {/* <img src={Gcash} style={{ width: "100%", height: "100%", opacity: 1, objectFit: 'contain' }} /> */}
                    </div>
                  </div>
                  {/* <div style={{ width: 40, height: 40, display: 'flex', position: 'absolute', right: "-2px", bottom: "-2px" }}>
                            <img src={SelectedIcon} style={{ width: "100%", height: '100%', opacity: 1, }} />
                        </div> */}
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <NoDataComp
                message={"No Payment Accounts Found. Please Add New."}
                color="#FF5746"
              />
              <div style={{ display: "flex", margin: "15px 0" }}>
                <p className={styles.enterAmountText}>{errorMessage}</p>
              </div>
            </div>
          )}

          <div
            className={`ml-1 ${styles.addWalletContainer} ${
              paymentMethods?.length < 3 || loader
                ? styles.buttonActive
                : styles.disabled
            }`}
            onClick={addWallet}
          >
            <div style={{ display: "flex", width: 28, height: 28 }}>
              <img src={addEWallet} className="imgStyle" alt="addEwalletIcon" />
            </div>
            <div style={{ display: "flex" }}>
              <p className={styles.addeWalletTextStyling}>
                {t(`Add E-Wallet`)}
              </p>
            </div>
          </div>
        </div>
        <div className={`w-[100%] ${styles.addPaymentContainer}`}>
          <AddPaymentMethod
            selectedWallet={selectedWallet}
            clearFields={clearFields}
            setClearFields={setClearFields}
            buttonText={buttonText}
            paymentMethodsInfo={paymentMethods}
            getBankListFunc={getBankListFunc}
          />
        </div>
      </div>
    </div>
  );
};

export default EwalletManagement;
