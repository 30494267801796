import { useEffect, useRef, useState } from "react";
import styles from "./CategoryAndGameSection.module.css";
import CategoryComponentHorizental from "./CategoryComponentHorizental";
import { useDispatch, useSelector } from "react-redux";
import useLogout from "../../../../layouts/Header/useLogout";
import useApiPlayGame from "../../../../Helper/useApiPlayGame";
import LoginPopupModal from "../../../Helper/LoginPopupModal";
import MyLoader from "../../../shared-components/Loader/MyLoader";
import { setActiveCatId } from "../../../../redux/Slice/CategoriesSlice";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import playIcon from "../../../../assets/PlayNowButton.png";
import Loader from "../../../shared-components/Loader/Loader";
import { Col, Row, Typography } from "antd";
import LeftIcon from "../../../../assets/Left.svg";
import RightIcon from "../../../../assets/Right.svg";
import { useTranslation } from "react-i18next";

const CategoryComponent = ({ category, trendingGames, segmentId }) => {
  const userReduxData = useSelector((state) => state?.user?.user);
  const [loadingGameId, setLoadingGameId] = useState(null);
  const [popupError, setPopupError] = useState("");
  const logout = useLogout();
  const { APIPlayGameFunc, isLoading } = useApiPlayGame(
    userReduxData,
    setLoadingGameId,
    setPopupError,
    logout
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const scrollableContainerRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [initialMouseX, setInitialMouseX] = useState(0);
  const [initialScrollX, setInitialScrollX] = useState(0);

  const funcCall = (category) => {
    dispatch(setActiveCatId(category?.id));
    navigate(`/${JSON.parse(category.name).en.toLowerCase()}`);
  };

  const handleMouseDown = (event) => {
    setIsMouseDown(true);
    setInitialMouseX(event.clientX);
    setInitialScrollX(scrollableContainerRef.current.scrollLeft);
    scrollableContainerRef.current.style.cursor = "grabbing";
  };

  const handleMouseMove = (event) => {
    if (!isMouseDown) return;
    const deltaX = event.clientX - initialMouseX;
    scrollableContainerRef.current.scrollLeft = initialScrollX - deltaX;
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    scrollableContainerRef.current.style.cursor = "grab";
  };

  const handleLeftClick = () => {
    const scrollableContainerRef = document.getElementById(
      `scrollable-container-${category}`
    );
    scrollableContainerRef.scrollTo({
      left: scrollableContainerRef.scrollLeft - 250,
      behavior: "smooth",
    });
  };

  const handleRightClick = () => {
    const scrollableContainerRef = document.getElementById(
      `scrollable-container-${category}`
    );
    scrollableContainerRef.scrollTo({
      left: scrollableContainerRef.scrollLeft + 250,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div>
        {trendingGames && (
          <div className={styles.trendingWrapper}>
            {trendingGames?.map((game, index) => {
              return (
                <div
                  key={index}
                  className={styles.trendingContainer}
                  onClick={() => funcCall(game)}
                >
                  <img
                    src={game?.icon_trend}
                    alt={index}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div>
        {category?.length > 0 ? (
          category?.map((child, index) => {
            return (
              <div key={child?.id}>
                {child?.child_categories?.length > 0 ? (
                  <CategoryComponentHorizental
                    category={child}
                    APIPlayGameFunc={APIPlayGameFunc}
                  />
                ) : (
                  <CategoryComponent
                    key={index}
                    category={child}
                    // segmentId={category?.id}
                  />
                )}
              </div>
            );
          })
        ) : (
          <div className={`${styles.parentDivStyling} category-grid`}>
            {/* Populaar section Header */}
            <div className={styles.parentDivStyle}>
              <div className={styles.leftContentStyle}>
                <Typography.Text className={styles.categoryTitle}>
                  {t(category?.name && JSON?.parse(category?.name)?.en)}
                </Typography.Text>
              </div>
              <div className={styles.rightContentStyle}>
                <img
                  src={LeftIcon}
                  className={styles.newArrIcon}
                  alt="LeftIcon"
                  onClick={handleLeftClick}
                  style={{ cursor: "pointer" }}
                />
                <img
                  src={RightIcon}
                  className={styles.newArrIcon}
                  alt="RightIcon"
                  onClick={handleRightClick}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div
              className="scrollable-container"
              id={`scrollable-container-${category}`}
              ref={scrollableContainerRef}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              <div
                className="flex !gap-3"
                // gutter={category?.game_items?.length < 9 ? 50 : 10}
                // wrap={false}
              >
                {category?.game_items?.map((game) => (
                  <div
                    key={game.id}
                    style={{
                      padding: "10px 4px",
                    }}
                  >
                    {game.id === loadingGameId ? (
                      <div
                        className={styles.imageStyling}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <div className={`${styles.imageContainer} `}>
                        <div className={styles.imageOverlay}>
                          <div className={styles.playButtonContainer}>
                            <img
                              src={playIcon}
                              alt="playIcon"
                              className="imgStyle"
                              onClick={() => APIPlayGameFunc(game.id)}
                            />
                          </div>
                        </div>
                        <img
                          style={{ cursor: "pointer" }}
                          loading="lazy"
                          src={game?.icon_square}
                          alt={`Img${game.id}`}
                          className={styles.imageStyling}
                          onClick={() => APIPlayGameFunc(game.id)}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {popupError && (
          <LoginPopupModal
            message={popupError}
            show={popupError ? true : false}
            hideModal={() => {
              setPopupError("");
              if (
                popupError ===
                "Player is not allowed to play games, Please contact the customer support to activate your account."
              ) {
                navigate("/support");
              }
            }}
          />
        )}
      </div>
    </>
  );
};
export default CategoryComponent;
