import { api } from "./axios";

// get customer created Banks API (for withdraw)
export const bankListAPI = async () => {
    try {
        const res = await api.get("/player/banks", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
        });
        if (res && res.data) {
            return res.data;
        }
    } catch (e) {
        console.log(e);
        return e
    }
    return null;
};
export const userBankCodes = async () => {
    try {
        const res = await api.get("/user/bank/codes", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
        });
        if (res && res.data) {
            return res.data;
        }
    } catch (e) {
        console.log(e);
        return e
    }
    return null;
};


// get enabled user banks for withdraw 
export const activebankListAPI = async () => {
    try {
        const res = await api.get("/player/active/banks", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('auth_token')}`
            }
        });
        if (res.data?.status) {
            return res.data
        }
    } catch (e) {
        // console.log(e);
    }
    return null;
}


// get player withdraw payment methods

export const playerWithdrawPaymentMethod = async () => {
    try {
        const res = await api.get("player/withdraw/payment/methods", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('auth_token')}`
            }
        });
        if (res?.status === 200) {
            return res
        }
    } catch (e) {
        // console.log(e);
        return e;
    }
    return null;
}

//user payment methods list
export const userPaymentMethodList = async () => {
    try {
        const res = await api.get("player/user/payment/method/list", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('auth_token')}`
            }
        });
        if (res?.status === 200) {
            return res
        }
    } catch (e) {
        // console.log(e);
            return e;
    }
    return null;
}