import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
        userBalance: null,
        userInfo: null,
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUserBalance: (state, action) => {
            state.userBalance = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
    },
});

export const { setUser, setUserBalance, setUserInfo } = UserSlice.actions;

export default UserSlice.reducer;
