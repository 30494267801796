import React from "react";
import styles from "./customButton.module.css";

const CustomButtonAnt = ({
  onClick,
  children,
  background,
  width,
  height,
  color,
  type,
  className,
  disabled = false,
  htmlType = "",
  icon,
}) => {
  const buttonStyle = {
    background: background || " #3D3279 0% 0% no-repeat padding-box",
    border: "1px solid #3B3F7B",
    cursor: "pointer",
    color: color || "#FFFFFF",
    width: width || "auto",
    height: height || "auto",
    paddingRight: "8px",
  };

  return (
    <div
      style={buttonStyle}
      className={` ${className} gap-1 flex mx-auto rounded-lg overflow-hidden`}
    >
      <img className="top-0 left-0 p-1" src={icon} alt="icon" />

      <button
        className={`${styles.btnContainer}`}
        onClick={onClick}
        type={type}
        disabled={disabled}
        // htmlType={htmlType}
      >
        {children}
      </button>
    </div>
  );
};

export default CustomButtonAnt;
