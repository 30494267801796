import { createSlice } from '@reduxjs/toolkit';

export const TrendingSlice = createSlice({
    name: 'trending',
    initialState: {
        trendingGames: null,
    },
    reducers: {
        setTrendingGames: (state, action) => {
            state.trendingGames = action.payload;
        },
    },
});

export const { setTrendingGames } = TrendingSlice.actions;


export default TrendingSlice.reducer;
