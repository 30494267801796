import React, { useEffect, useState } from "react";
import styles from "./DepositInput.module.css";
import selectedIcon from "../../../../assets/images/payment icons/Selected Method.png";
import {
  CommaSeperator,
  handleKeyPress,
  toFixedFunc,
} from "../../../Helper/HelperFunction";
import { currencyHelper } from "../../../Helper/currencyHelper";
import InputField from "../../../shared-components/inputField/InputField";
import SmallLoader from "../../../shared-components/Loader/SmallLoader";
import { useTranslation } from "react-i18next";

const inputBox = {
  width: "100%",
  height: "50px",
  background: "#000326 0% 0% no-repeat padding-box",
  border: "1px solid #3C3562",
  borderRadius: "7px",
  opacity: 1,
  textAlign: "left",
  fontFamily: "Roboto",
  fontWeight: "normal",
  lineHeight: "19px",
  fontSize: "18px",
  letterSpacing: 0,
  color: "#FFFFFF",
  // margin: "30px auto 0px auto",
};

const DepositInput = (props) => {
  const { t } = useTranslation();
  const {
    depositMinAmount,
    depositMaxAmount,
    amount,
    userInfoReduxData,
    handleInputChange,
    sugestedAmount,
    bankPaymentmethod,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    handleSelectAmount,
    fetchDataLoading,
  } = props;

  const placeholderText = `Min ${CommaSeperator(
    toFixedFunc(depositMinAmount)
  )} ~ Max ${CommaSeperator(toFixedFunc(depositMaxAmount))}`;

  return (
    <>
      <div className={styles.cardWrapper}>
        <div className={styles.paymentMethodWrapper}>
          {/* <div className={styles.paymentMethodBtnContainer}>
              {bankPaymentmethod?.map((method) => (
                <div className={styles.paymentMethodSubContainer}>
                  <button
                    style={{
                      border: bankPaymentmethod && bankPaymentmethod.id === method.id ? "2px solid #FFFFFF" : "",
                      position: "relative",
                    }}
                    className={styles.amountBtn}
                    key={method.id}
                  >
                    {method?.en_public_name}
                    {bankPaymentmethod && bankPaymentmethod.id === method.id ? (
                      <span>
                        <img
                          src={selectedIcon}
                          style={{
                            width: "20px",
                            height: "20px",
                            position: "absolute",
                            right: "0",
                            bottom: "0",
                            objectFit: "contain",
                          }}
                          alt="selectedIcon"
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              ))}
            </div>
           */}
          <div>
            {fetchDataLoading ? (
              <SmallLoader />
            ) : (
              bankPaymentmethod?.map((method) => (
                <button
                  onClick={() => setSelectedPaymentMethod(method)}
                  style={{
                    marginRight: "30px",
                    marginBottom: "10px",
                    height: "45px",
                    width: "160px",
                    border:
                      selectedPaymentMethod &&
                      selectedPaymentMethod.id === method.id
                        ? "2px solid #FFFFFF"
                        : "",
                    position: "relative",
                    padding: "5px",
                    color: "white",
                    background:
                      " transparent linear-gradient(180deg, #2C2164 0%, #3E337A 100%) 0% 0% no-repeat padding-box",
                    borderRadius: "10px",
                    opacity: 1,
                    font: "font: normal normal normal 16px/25px Poppins",
                  }}
                  key={method.id}
                  type="button"
                >
                  {method?.en_public_name}
                  {selectedPaymentMethod &&
                  selectedPaymentMethod.id === method.id ? (
                    <span>
                      <img
                        src={selectedIcon}
                        style={{
                          width: "35px",
                          height: "35px",
                          position: "absolute",
                          right: "0px",
                          bottom: "0px",
                          objectFit: "contain",
                        }}
                        alt="selectedIcon"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </button>
              ))
            )}
          </div>
          <hr
            style={{
              marginTop: "20px",
              marginBottom: "30px",
              borderColor: "black",
              height: "3px",
              borderStyle: "none",
              borderTop: "2px solid #14132D",
            }}
          ></hr>

          <div className={styles.amountContainer}>
            <div>
              <p style={{ display: "inline", color: "#BE8AFF" }}>
                <span className={styles.sectionLabel}>
                  {t(`Amount`)} &nbsp;
                </span>
              </p>
              <p
                className={styles.amountTextStyle}
                style={{ display: "inline" }}
              >
                ({CommaSeperator(toFixedFunc(depositMinAmount))} ~{" "}
                {CommaSeperator(toFixedFunc(depositMaxAmount))})
              </p>
            </div>
          </div>
          <div className={styles.amountBtnWrapper}>
            <div className="flex items-center w-full mt-7 mb-5">
              <span
                style={{
                  textAlign: "left",
                  font: "normal normal normal 18px/27px Poppins",
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  opacity: 1,
                  fontWeight: "300",
                  alignContent: "center",
                  flex: "none", // Ensure the text does not grow
                  width: "180px", // Adjust width as needed
                }}
              >
                {t(`depositAmount`)}:
              </span>
              <div style={{ flex: "1" }}>
                <InputField
                  style={{ width: "100%" }}
                  placeHolder={`${CommaSeperator(
                    toFixedFunc(depositMinAmount)
                  )} - ${CommaSeperator(toFixedFunc(depositMaxAmount))}`}
                  extraStyle={inputBox}
                  type="text"
                  name="amount"
                  prefix={
                    <p
                      style={{
                        fontSize: "30px",
                        opacity: 1,
                        color: amount === "" ? "#574F85" : "#FFFFFF",
                      }}
                    >
                      {console.log(userInfoReduxData?.currency)}
                      {currencyHelper(userInfoReduxData?.currency)}
                    </p>
                  }
                  value={amount}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>

            <div className={styles.buttonContainer}>
              {sugestedAmount?.map((btn) => (
                <div className={styles.subContainer}>
                  <button
                    style={{
                      border: amount === btn.value ? "2px solid #FFFFFF" : "",
                      position: "relative",
                    }}
                    className={styles.amountBtn}
                    key={btn.id}
                    onClick={(e) => handleSelectAmount(e, btn)}
                  >
                    <span style={{ fontFamily: "Poppins" }}>{btn.label}</span>
                    {amount === btn.value ? (
                      <span>
                        <img
                          src={selectedIcon}
                          style={{
                            width: "35px",
                            height: "33px",
                            position: "absolute",
                            right: "0",
                            bottom: "0",
                            objectFit: "contain",
                          }}
                          alt="selectedIcon"
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositInput;
