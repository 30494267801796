import React, { useEffect, useState } from "react";
import backimage from "../../../assets/images/back-icon/BackIcon.png";
import styles from "./BettingRecord.module.css";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BettingRecordTable from "./components/BettingRecordTable";
import { useSelector } from "react-redux";
import {
  getBettingRecord,
  getBettingRecordProviders,
} from "../../../Api/BettingRecorApi";
import { Pagination, Select } from "antd";
import preIcon from "../../../assets/images/swip/Left Swip.png";
import nextIcon from "../../../assets/images/swip/Right Swip.png";
import { useTranslation } from "react-i18next";

const BettingRecord = () => {
  const { t } = useTranslation();
  const userInfoReduxData = useSelector((state) => state?.user?.userInfo);
  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
    providers: null,
  });
  const [bettingRecordData, setBettingRecordData] = useState([]);
  const [methodLoading, setMethodLoading] = useState(false);
  const [bettingProviders, setBettingProviders] = useState([]);
  const [runClearFunc, setRunClearFunc] = useState(false);
  const today = new Date();
  const [errors, setErrors] = useState({});
  const options = bettingProviders.map((provider) => ({
    value: provider.id,
    label: provider.name,
  }));
  const [showViewMore, setShowViewMore] = useState(true);
  const [pageData, setPageData] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchProviders = async () => {
      setMethodLoading(true);
      try {
        const res = await getBettingRecordProviders();
        if (res) {
          setBettingProviders(res);
        }
      } catch (error) {
        console.error("Error fetching betting record providers:", error);
      }
      setMethodLoading(false);
    };
    fetchProviders();
  }, []);

  const dateHandleChange = (date, field) => {
    // console.log("DateType : ", date)
    setFilterData({
      ...filterData,
      [field]: date,
    });
  };

  const providersHandleChange = (value) => {
    setFilterData({
      ...filterData,
      providers: value,
    });
  };

  const handleResetFilter = () => {
    setPage(1);
    setFilterData({
      startDate: null,
      endDate: null,
      providers: null,
    });
    setErrors({});
    setRunClearFunc(true);
  };

  useEffect(() => {
    if (
      runClearFunc &&
      filterData?.startDate == null &&
      filterData?.endDate == null &&
      filterData?.providers == null
    ) {
      fetchData();
    }
  }, [filterData]);

  const formatDate = (date, isEndDate = false) => {
    if (!date) return null;
    const d = new Date(date);
    // console.log("jgbwj : ",d)
    const year = d.getUTCFullYear();
    const month = ("0" + (d.getUTCMonth() + 1)).slice(-2); // Months are 0-based
    const day = ("0" + d.getUTCDate()).slice(-2);
    const hours = ("0" + d.getUTCHours()).slice(-2);
    const minutes = ("0" + d.getUTCMinutes()).slice(-2);
    const seconds = ("0" + d.getUTCSeconds()).slice(-2);
    // console.log("utc : ",`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`)
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const validateDates = () => {
    const errors = {};
    const startDateFormatted = formatDate(filterData.startDate);
    const endDateFormatted = formatDate(filterData.endDate, true);

    if (filterData.startDate && !startDateFormatted) {
      errors.from_date =
        t("The from date field must match the format Y-m-d H:i:s.");
    }
    if (filterData.endDate && !endDateFormatted) {
      errors.to_date = t("The to date field must match the format Y-m-d H:i:s.");
    }
    if (filterData.startDate && !filterData.endDate) {
      if (filterData.startDate > today) {
        errors.from_date = t("From date should not be greater than today");
      } else {
        errors.to_date = t("Enter the To date field");
      }
    } else if (filterData.startDate && filterData.endDate) {
      if (filterData.startDate > filterData.endDate) {
        errors.to_date = t("End date should not be lesser than from date");
        if (filterData.startDate > today) {
          errors.from_date = t("From date should not be greater than today");
        }
      } else if (filterData.startDate > today) {
        errors.from_date = t("From date is greater than today");
      }
    }
    if (filterData.endDate && !filterData.startDate) {
      if (filterData.endDate > today) {
        errors.to_date = t("To date is greater than today");
      } else {
        errors.from_date = t("Enter the From date field");
      }
    } else if (filterData.endDate && filterData.startDate) {
      if (filterData.endDate < filterData.startDate) {
        errors.to_date = t("End date should not be lesser than from date");
        if (filterData.endDate > today) {
          errors.to_date = "To date is greater than today";
        }
      } else if (filterData.endDate > today) {
        errors.to_date = t("To date is greater than today");
      }
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSearch = () => {
    setPage(1); // Reset page to 1
    if (validateDates()) {
      fetchData(); // Fetch data only if validation passes
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    setMethodLoading(true);
    try {
      const res = await getBettingRecord(
        userInfoReduxData?.id,
        filterData.providers,
        formatDate(filterData.startDate),
        formatDate(filterData.endDate, true),
        page
      );
      if (res) {
        setBettingRecordData(res?.betRounds?.data || []);
        setPageData(res?.betRounds);
      }
    } catch (error) {
      console.error("Error fetching betting record:", error);
      setBettingRecordData([]);
    }
    setMethodLoading(false);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <div
          style={{ maxWidth: 9, width: "100%", maxHeight: 14, height: "100%" }}
        >
          <img src={preIcon} className="imgStyle" alt="preimg" />
        </div>
      );
    }
    if (type === "next") {
      return (
        <div
          style={{ maxWidth: 9, width: "100%", maxHeight: 14, height: "100%" }}
        >
          <img src={nextIcon} className="imgStyle" alt="nextimg" />
        </div>
      );
    }

    return originalElement;
  };

  return (
    <div className="min-h-screen p-7">
      <div className="flex mt-4 space-x-5 justify-normal items-center">
        <Link to="/account">
          <img
            src={backimage}
            alt="back"
            style={{ width: "13px", height: "21px" }}
          />
        </Link>
        <h2 className={styles.headTitleDesign}>{t(`Betting Record`)}</h2>
      </div>

      <div className="flex flex-row gap-2 flex-wrap mt-8">
        <div className="flex flex-row items-center gap-4 p-2 rounded-md">
          <h2
            style={{
              textAlign: "left",
              font: "normal normal normal 14px/36px Poppins",
              letterSpacing: "0px",
              color: "#FFFFFF",
              textTransform: "capitalize",
              opacity: 1,
            }}
          >
            {t(`From`)}
          </h2>
          <DatePicker
            selected={filterData.startDate}
            onChange={(date) => dateHandleChange(date, "startDate")}
            className={`!w-40 lg:!w-[180px] text-white rounded-md bg-default h-7 ${styles.customDatePicker}`}
            dateFormat="dd/MM/yyyy"
            placeholderText=" DD/MM/YYYY"
            maxDate={today}
          />
        </div>

        <div className="flex flex-row items-center gap-4  rounded-md">
          <h2
            style={{
              textAlign: "left",
              font: "normal normal normal 14px/36px Poppins",
              letterSpacing: "0px",
              color: "#FFFFFF",
              textTransform: "capitalize",
              opacity: 1,
            }}
          >
            {t(`To`)}
          </h2>
          <DatePicker
            selected={filterData.endDate}
            onChange={(date) => dateHandleChange(date, "endDate")}
            className={`!w-40 lg:!w-[180px] text-white rounded-md bg-default h-7 ${styles.customDatePicker}`}
            dateFormat="dd/MM/yyyy"
            placeholderText=" DD/MM/YYYY"
            maxDate={today}
          />
        </div>
        <div className="flex flex-row items-center gap-4 p-2 rounded-md ml-0 xl:ml-16">
          <h2
            style={{
              // marginLeft: "10px",
              textAlign: "left",
              font: "normal normal normal 14px/36px Poppins",
              letterSpacing: "0px",
              color: "#FFFFFF",
              textTransform: "capitalize",
              opacity: 1,
            }}
          >
            {t(`Providers`)}
          </h2>
          <Select
            style={{
              border: "1px solid #3B3F7B",
              borderRadius: "7px",
              color: "#574f85",
            }}
            className={`w-40 add-payment-methods`}
            value={filterData.providers}
            onChange={providersHandleChange}
            placeholder="Select"
            options={options}
          />

          {/* <select
            className={`w-40 text-white rounded-md bg-default h-7 ${styles.customSelector}`}
            value={filterData.providers || ""}
            onChange={providersHandleChange}
          >
            <option value="" disabled>
              Select
            </option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select> */}
        </div>
        <div className="flex gap-2 mt-2 ml-0 xl:ml-10">
          <button
            className={`${styles.customButtonSubmit}`}
            style={{
              background:
                "transparent linear-gradient(180deg, #5158DE 0%, #613ADE 50%, #711ADE 100%) 0% 0% no-repeat padding-box",
              borderRadius: "7px",
              opacity: 1,
            }}
            onClick={handleSearch}
          >
            {t(`Search`)}
          </button>

          <button
            className={`${styles.customButtonReset}`}
            style={{
              background: "#000326 0% 0% no-repeat padding-box;",
              border: "1px solid #3B3F7B",
              borderRadius: "7px",
              opacity: 1,
            }}
            onClick={handleResetFilter}
          >
            {t(`Reset`)}
          </button>
        </div>
      </div>
      <div style={{ display: "flex", marginLeft: "3rem" }}>
        {errors.from_date && (
          <div
            className="text-red-500"
            style={{ marginRight: errors.to_date ? "3rem" : "15rem" }}
          >
            {errors.from_date}
          </div>
        )}
        {errors.to_date && (
          <div
            className="text-red-500"
            style={{ marginLeft: errors.from_date ? "0" : "15rem" }}
          >
            {errors.to_date}
          </div>
        )}
      </div>

      <BettingRecordTable
        bettingRecordData={bettingRecordData}
        methodLoading={methodLoading}
      />

      {methodLoading ? (
        <></>
      ) : (
        <>
          {showViewMore && bettingRecordData?.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                margin: "20px 0",
                marginBottom: "100px",
              }}
            >
              <Pagination
                className="custom-pagination"
                defaultCurrent={page}
                current={pageData?.current_page}
                pageSize={pageData?.per_page}
                total={pageData?.total}
                onChange={setPage}
                showSizeChanger={false}
                itemRender={itemRender}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BettingRecord;
