import React, { useEffect, useRef, useState } from "react";
import styles from "./CategoryAndGameSection.module.css";
import { useSelector } from "react-redux";
import useLogout from "../../../../layouts/Header/useLogout";
import useApiPlayGame from "../../../../Helper/useApiPlayGame";
import LoginPopupModal from "../../../Helper/LoginPopupModal";
import playIcon from "../../../../assets/PlayNowButton.png";
// import { Col, Row } from "antd";
import Loader from "../../../shared-components/Loader/Loader";
import LeftIcon from "../../../../assets/Left.svg";
import RightIcon from "../../../../assets/Right.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CategoryComponentHorizental = ({ category }) => {
  const { t } = useTranslation();
  const userReduxData = useSelector((state) => state?.user?.user);
  const [loadingGameId, setLoadingGameId] = useState(null);
  const [popupError, setPopupError] = useState("");
  const logout = useLogout();
  const navigate = useNavigate();
  const { APIPlayGameFunc, isLoading } = useApiPlayGame(
    userReduxData,
    setLoadingGameId,
    setPopupError,
    logout
  );
  const [tabValue, setTableValue] = useState();
  const [showgames, setShowGames] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollableContainerRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [initialMouseX, setInitialMouseX] = useState(0);
  const [initialScrollX, setInitialScrollX] = useState(0);
  // const [selectedScrollId, setSelectedScrollId] = useState();

  const handleMouseDown = (event) => {
    setIsMouseDown(true);
    setInitialMouseX(event.clientX);
    setInitialScrollX(scrollableContainerRef.current.scrollLeft);
    scrollableContainerRef.current.style.cursor = "grabbing";
  };

  const handleMouseMove = (event) => {
    if (!isMouseDown) return;
    const deltaX = event.clientX - initialMouseX;
    scrollableContainerRef.current.scrollLeft = initialScrollX - deltaX;
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    scrollableContainerRef.current.style.cursor = "grab";
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTableValue(category.child_categories[0]?.id);
  }, [category]);

  useEffect(() => {
    if (tabValue) {
      setShowGames(
        category.child_categories.find((child) => child.id === tabValue)
          ?.game_items
      );

      // Reset scroll position to left (0) whenever tabValue changes
      if (scrollableContainerRef.current) {
        scrollableContainerRef.current.scrollTo({
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, [tabValue]);

  const scrollLeft = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={`${styles.childDivStyling} category-grid`}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className={styles.categoryTitle}>
          {t(`${category?.name && JSON?.parse(category?.name)?.en}`)}
        </p>
      </div>

      <div className={styles.verticalFlexStyle}>
        {category?.child_categories?.length && (
          <div className={styles.horizentalDivStyle}>
            <div className="flex">
              {category?.child_categories?.map((child, index) => {
                return (
                  <div
                    key={child.id}
                    style={
                      child.id === tabValue
                        ? { borderBottom: "1px solid #FFFFFF" }
                        : { borderBottom: "none" }
                    }
                    onClick={() => setTableValue(child.id)}
                  >
                    <p
                      style={{
                        color: child.id === tabValue ? "#FFFFFF" : "#7473B7",
                      }}
                      className={styles.horizentalText}
                    >
                      {t(`${child?.name && JSON?.parse(child?.name)?.en}`)}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className={styles.rightContentStyle}>
              <img
                style={{ cursor: "pointer" }}
                src={LeftIcon}
                className={styles.newArrIcon}
                alt="LeftIcon"
                onClick={scrollLeft}
              />
              <img
                style={{ cursor: "pointer" }}
                src={RightIcon}
                className={styles.newArrIcon}
                alt="RightIcon"
                onClick={scrollRight}
              />
            </div>
          </div>
        )}

        <div
          className="scrollable-container"
          id={`scrollable-container-${category}`}
          ref={scrollableContainerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div className="flex !gap-3">
            {showgames.map((game) => (
              <div
                key={game.id}
                style={{
                  padding: "10px 4px",
                }}
              >
                {game.id === loadingGameId ? (
                  <div
                    className={styles.imageStyling}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <div className={`${styles.imageContainer}`}>
                    <div className={styles.imageOverlay}>
                      <div className={styles.playButtonContainer}>
                        <img
                          src={playIcon}
                          alt="playIcon"
                          className="imgStyle"
                          onClick={() => APIPlayGameFunc(game.id)}
                        />
                      </div>
                    </div>
                    <img
                      style={{ cursor: "pointer" }}
                      loading="lazy"
                      src={game?.icon_square}
                      alt={`Img${game.id}`}
                      className={styles.imageStyling}
                      onClick={() => APIPlayGameFunc(game.id)}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {popupError && (
        <LoginPopupModal
          message={popupError}
          show={popupError ? true : false}
          hideModal={() => {
            setPopupError("");
            if (
              popupError ===
              "Player is not allowed to play games, Please contact the customer support to activate your account."
            ) {
              navigate("/support");
            }
          }}
        />
      )}
    </div>
  );
};

export default CategoryComponentHorizental;
