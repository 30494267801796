import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import config from "./config.json";

const root = ReactDOM.createRoot(document.getElementById("root"));
// const pathname = window.location.pathname;
// // Extract the first two segments of the pathname
// const pathSegments = pathname.split("/").filter(Boolean);
// const pathKey = pathSegments.slice(0, 2).join("/");

// let basename;

// if (pathKey === "/") {
//   basename = "/ph/d";
// } else if (pathKey === "ph/d") {
//   basename = "/ph/d";
// } else if (pathKey === "bg/d") {
//   basename = "/bg/d";
// } else if (pathname) {
//   basename = "/";
// } else {
//   basename = "/ph/d";
// }

root.render(
  <React.StrictMode>
    <BrowserRouter basename={`${config.homepage}`}>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
