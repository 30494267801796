import React from "react";
import banner from "../../../assets/Banner@2x.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Banner = () => {
  return (
    <Carousel
      showArrows={false}
      showThumbs={false}
      autoPlay={true}
      infiniteLoop
      stopOnHover
      showStatus={false}
      // axis="Y"
      axis="horizontal"
      interval={3000}
      transitionTime={500}
    >
      <div>
        <img className="mt-5 p-7" src={banner} alt="banner" />
      </div>
      <div>
        <img className="mt-5 p-7" src={banner} alt="banner" />
      </div>
      <div>
        <img className="mt-5 p-7" src={banner} alt="banner" />
      </div>
    </Carousel>
  );
};

export default Banner;
