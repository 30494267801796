import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Home from "../components/pages/Home/Home";
import { useSelector } from "react-redux";
import MainPageLoading from "../components/shared-components/MainPageLoading/MainPageLoading";

const DynamicCategoryRoute = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { category } = useParams();
  const gameCategories = useSelector((state) => state.categories.allCategories);

  useEffect(() => {
    if (gameCategories.length > 0) {
      // Check category exist
      const categoryExists = gameCategories.some((cat) => {
        const categoryNameEn = JSON.parse(cat.name)?.en?.toLowerCase();
        return categoryNameEn === category.toLowerCase();
      });

      if (!categoryExists) {
        navigate("*");
      } else {
        setLoading(false);
      }
    }
  }, [category, gameCategories, navigate]);

  if (loading) {
    return (
      <div>
        <MainPageLoading />
      </div>
    );
  }

  return <Home />;
};

export default DynamicCategoryRoute;
