import React, { useState, useEffect } from "react";
import styles from "./Transaction.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import backimage from "../../../assets/images/back-icon/BackIcon.png";
import "react-datepicker/dist/react-datepicker.css";
import { APIGetAllTransactions } from "../../../Api/TransactionApi";
import { CommaSeperator, toFixedFunc } from "../../Helper/HelperFunction";
import NoDataComp from "../../shared-components/NoData/NoDataComp";
import useLogout from "../../../layouts/Header/useLogout";
import SmallLoader from "../../shared-components/Loader/SmallLoader";
import preIcon from "../../../assets/images/swip/Left Swip.png";
import nextIcon from "../../../assets/images/swip/Right Swip.png";
import { Button, DatePicker, Pagination, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const customStyle = {
  width: "220px",
  height: "36px",
  background: "#000326 0% 0% no-repeat padding-box",
  borderRadius: "7px",
  textAlign: "left",
  font: "normal normal normal 14px/36px Poppins",
  letterSpacing: "0px",
  color: "#FFFFFF",
  opacity: "1",
  backgroundColor: "#374165",
  border: "1px solid #3B3F7B",
  color: "white",
};

const endDateFormat = (inputDate) => {
  if (!inputDate) return null;
  const d = new Date(inputDate);
  // console.log("jgbwj : ", d)
  const year = d.getUTCFullYear();
  const month = ("0" + (d.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + d.getUTCDate()).slice(-2);
  const hours = ("0" + d.getUTCHours()).slice(-2);
  const minutes = ("0" + d.getUTCMinutes()).slice(-2);
  const seconds = ("0" + d.getUTCSeconds()).slice(-2);
  // console.log("utc : ",`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`)
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
const startDateFormat = (inputDate) => {
  if (!inputDate) return null;
  const d = new Date(inputDate);
  // console.log("jgbwj : ", d)
  const year = d.getUTCFullYear();
  const month = ("0" + (d.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + d.getUTCDate()).slice(-2);
  const hours = ("0" + d.getUTCHours()).slice(-2);
  const minutes = ("0" + d.getUTCMinutes()).slice(-2);
  const seconds = ("0" + d.getUTCSeconds()).slice(-2);
  // console.log("utc : ",`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`)
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const Transactions = () => {
  const { t } = useTranslation();

  const [pageData, setPageData] = useState(null);
  const [page, setPage] = useState(1);
  const [showViewMore, setShowViewMore] = useState(true);

  const dateFormat = "DD-MM";
  const logout = useLogout();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [loading, setLoading] = useState(false);
  const [toggleButton, setToggleButon] = useState({
    deposit: state?.active === "withdraw" ? false : true,
    withdrawal: state?.active === "withdraw" ? true : false,
  });
  const [dateRange, setDateRange] = useState([]);
  const [filterData, setFilterData] = useState({
    startDate: "",
    endDate: "",
    status: null,
  });
  const [allTransactions, setAllTransactions] = useState([]);

  const statusHandleChange = (value) => {
    setFilterData({
      ...filterData,
      status: value,
    });
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return (
        <div
          style={{ maxWidth: 9, width: "100%", maxHeight: 14, height: "100%" }}
        >
          <img src={preIcon} className="imgStyle" alt="preimg" />
        </div>
      );
    }
    if (type === "next") {
      return (
        <div
          style={{ maxWidth: 9, width: "100%", maxHeight: 14, height: "100%" }}
        >
          <img src={nextIcon} className="imgStyle" alt="nextimg" />
        </div>
      );
    }

    return originalElement;
  };

  useEffect(() => {
    getAllTransactionsFunc(page);
  }, [page]);

  const dateHandleChange = (range) => {
    setDateRange(range);
    if (range && range.length === 2) {
      const startDate = range[0].format();
      const endDate = range[1].format();
      setFilterData({
        ...filterData,
        startDate: startDateFormat(startDate),
        endDate: endDateFormat(endDate),
      });
    }
  };
  const handleResetFilter = () => {
    // setDateRange([]);
    // setFilterData({
    //   startDate: "",
    //   endDate: "",
    //   status: null,
    // });
    // getAllTransactionsFunc();
    setDateRange([]);
    const resetFilterData = {
      startDate: "",
      endDate: "",
      status: null,
    };
    setFilterData(resetFilterData);
    getAllTransactionsFunc(1, resetFilterData);
  };

  useEffect(() => {
    setPage(1);
    handleResetFilter();
  }, [toggleButton]);

  const handleToggleButn = (e) => {
    let buttonText = e.target.textContent;
    if (buttonText === t(`Deposit`)) {
      setToggleButon({
        deposit: true,
        withdrawal: false,
      });
    } else {
      setToggleButon({
        deposit: false,
        withdrawal: true,
      });
    }
  };
  const getAllTransactionsFunc = async (
    page = 1,
    filterDataParam = filterData
  ) => {
    setLoading(true);
    const res = await APIGetAllTransactions(
      filterDataParam,
      page,
      toggleButton?.withdrawal ? 1 : 0
    );
    // console.log("transaction", res);
    if (res?.status === 200) {
      if (res?.data?.data?.length > 0) {
        setAllTransactions(res?.data?.data);
      } else {
        setAllTransactions([]);
      }
      setPageData(res?.data);
    } else if (res?.response?.status === 401) {
      logout();
    } else if (res?.response?.status === 400) {
      if (res?.response?.data?.message === "INVALID_ACCESS") {
        logout();
      }
    } else {
      setAllTransactions([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllTransactionsFunc();
  }, []);

  const getFilteredTransactions = () => {
    return allTransactions.filter((transaction) =>
      toggleButton.deposit
        ? transaction.isWithdraw === 0
        : transaction.isWithdraw === 1
    );
  };

  const statusBackgroundColors = {
    Approved: "#004430",
    Rejected: "#4D000A",
    Pending: "#14132D",
    Processing: "#482B00",
    "Waiting For Payment": "#482B00",
    Failed: "#4D000A",
  };
  const statusBorderColors = {
    Approved: "#00FFB5",
    Rejected: "#FF5757",
    Pending: "#BE8AFF",
    Processing: "#FFC36A",
    "Waiting For Payment": "#FFC36A",
    Failed: "#FF5757",
  };
  const statusTextColors = {
    Approved: "#00FFB5",
    Rejected: "#FF5757",
    Pending: "#BE8AFF",
    Processing: "#FFC36A",
    "Waiting For Payment": "#FFC36A",
    Failed: "#FF5757",
  };

  return (
    <div className="min-h-[60vh] p-7">
      {/* Top */}
      <div className="flex mt-4 space-x-5 justify-normal items-center">
        <Link to="/account">
          <img
            src={backimage}
            alt="back"
            style={{ width: "13px", height: "21px" }}
          />
        </Link>
        <h2 className={styles.headTitleDesign}>
          {toggleButton.deposit ? t(`Deposit Record`) : t(`Withdraw Record`)}
        </h2>
      </div>

      {/* Toggle */}
      <div className="flex items-center w-full mt-5">
        <div
          className="relative flex items-center h-12 p-1 rounded-full w-96 bg-body"
          style={{ border: "1px solid #3B3F7B" }}
        >
          <div
            className={`absolute top-0 bottom-0 left-0 right-0 m-1 bg-toggle rounded-full transition-transform transform ${
              toggleButton.deposit ? "" : "translate-x-[95%]"
            }`}
            style={{ width: "50%" }}
          ></div>
          <button
          className={` ${
            styles.toggleText
          } flex-1 h-9  z-10 focus:outline-none ${
            toggleButton.deposit ? "text-white" : "text-[#7F72C6]"
          }`}
            // className={`flex-1 text-center z-10 text-white focus:outline-none ${
            //   toggleButton.deposit ? "text-white" : "text-[#7F72C6]" //"font-normal" : "font-light"
            // }`}
            onClick={handleToggleButn}
          >
            {t(`Deposit`)}
          </button>
          <button
           className={` ${
            styles.toggleText
          } flex-1 h-9  z-10 focus:outline-none ${
            toggleButton.withdrawal ? "text-white" : "text-[#7F72C6]"
          }`}
            // className={`flex-1 text-center z-10 text-white focus:outline-none ${
            //   toggleButton.withdrawal ?  "text-white" : "text-[#7F72C6]"   //"font-normal" : "font-light"
            // }`}
            onClick={handleToggleButn}
          >
            {t(`Withdrawal`)}
          </button>
        </div>
      </div>

      {/* Filters */}
      <div className="flex flex-row flex-wrap gap-4 mt-8">
        <div className={"flex flex-row items-center gap-4 p-2 rounded-md "}>
          <h2
            style={{
              textAlign: "left",
              font: "normal normal normal 14px/36px Poppins",
              letterSpacing: "0px",
              color: "#FFFFFF",
              textTransform: "capitalize",
              opacity: 1,
            }}
          >
            {t("Select Date")}
          </h2>
          <RangePicker
            style={{ ...customStyle }}
            format={dateFormat}
            value={dateRange}
            onChange={dateHandleChange}
            className= {`bg-default text-white`}
          />
        </div>
        <div className="transaction-select flex flex-row items-center gap-4 p-2 rounded-md ">
          <h2
            style={{
              textAlign: "left",
              font: "normal normal normal 14px/36px Poppins",
              letterSpacing: "0px",
              color: "#FFFFFF",
              textTransform: "capitalize",
              opacity: 1,
            }}
          >
            {t(`Status`)}
          </h2>
          <Select
            style={{ ...customStyle }}
            className={`transaction-selectOption text-white w-40`}
            placeholder={t("Select")}
            onChange={statusHandleChange}
            value={filterData.status}
            options={[
              { value: "1", label: t(`Approved`) },
              { value: "2", label: t(`Rejected`) },
              { value: "3", label: t(`Pending`) },
              { value: "4", label: t(`Waiting For Payment`) },
              { value: "5", label: t(`Processing`) },
              { value: "6", label: t(`Failed`) },
            ]}
          />
        </div>
        <div className="flex gap-4 mt-2">
          <button
            className={`${styles.customButtonSubmit}`}
            style={{
              background:
                "transparent linear-gradient(180deg, #5158DE 0%, #613ADE 50%, #711ADE 100%) 0% 0% no-repeat padding-box",
              borderRadius: "5px",
              border: "1px solid #3B3F7B",
              opacity: "1",
            }}
            type="primary"
            onClick={() => getAllTransactionsFunc(filterData)}
          >
            {t(`Search`)}
          </button>
          <button
            className={`${styles.customButtonReset}`}
            style={{
              color: "white",
              background: "#000326 0% 0% no-repeat padding-box",
              border: "1px solid #3B3F7B",
              borderRadius: "5px",
              opacity: "1",
            }}
            onClick={handleResetFilter}
          >
            {t(`Reset`)}
          </button>
        </div>
      </div>
      {/* Table */}
      <div className={`relative mt-10 overflow-x-auto shadow-md sm:rounded-lg`}>
        <table
          className={`w-full text-sm text-left rtl:text-right table-fixed ${styles.tableData}`}
        >
          <thead className={`text-sm bg-table ${styles.tableHeader}`}>
            <tr>
              <th
                style={{ width: "200px" }}
                scope="col"
                className="px-6 py-3 font-normal"
              >
                {t(`depositamt`)}
              </th>
              <th
                style={{ width: "200px" }}
                scope="col"
                className="px-6 py-3 font-normal"
              >
                {t(`time`)}
              </th>
              <th scope="col" className="px-6 py-3 font-normal">
                {t(`Status`)}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <></>
            ) : getFilteredTransactions()?.length ? (
              getFilteredTransactions()?.map((item) => {
                let date = new Date(item.created_at);
                const formattedDate = date.toLocaleString();
                const [datePart, timePart] = formattedDate.split(", ");
                const statusText =
                  item.status_name.charAt(0).toUpperCase() +
                  item.status_name.slice(1);
                // const statusColor =
                //   statusBackgroundColors[statusText] || "#FFFFFF";
                // const statusBorderColor =
                //   statusBorderColors[statusText] || "#FFFFFF";
                // const statusTextColor =
                //   statusTextColors[statusText] || "#FFFFFF";
                return (
                  <tr
                    key={item.id}
                    className="border-gray-700 bg-body"
                    onClick={() => navigate("/transections/" + item?.id)}
                  >
                    <td className="px-6 py-4 font-light text-white whitespace-nowrap">
                      {CommaSeperator(toFixedFunc(item.amount))}
                    </td>
                    <td className="px-6 py-4 font-light text-white whitespace-nowrap">
                      <div>
                        {timePart} {datePart}
                      </div>
                    </td>
                    <td className="px-6 py-4 font-normal text-white whitespace-nowrap">
                      <div
                        className={`border rounded-[25px] w-fit flex items-center justify-center min-w-[70px] h-[25px] lg:min-w-[100px] lg:h-[30px] lg:text-[14px] ${
                          item.status_name === "approved"
                            ? "border-[#00FFB5] bg-[#004430]"
                            : ""
                        } ${
                          item.status_name === "rejected" ||
                          item.status_name === "Failed"
                            ? "border-[#FF5757] bg-[#4D000A]"
                            : ""
                        } ${
                          item.status_name === "Waiting For Payment"
                            ? "border-[#FFC36A] bg-[#482B00]"
                            : ""
                        } ${
                          item.status_name === "pending"
                            ? "border-[#FFC36A] bg-[#482B00]"
                            : ""
                        }`}
                      >
                        {/* if in case item.status_name or anything is changing by backend the we need to translate the conditions too. so need to complete backend first */}
                        <Typography.Text
                          className={`${styles.itemStatusText} text-[10px] lg:text-[12px]`}
                          style={{
                            //
                            color:
                              item.status_name === "approved"
                                ? "#00FFB5"
                                : item.status_name === "rejected" ||
                                  item.status_name === "Failed"
                                ? "#FF5757"
                                : item.status_name === "Waiting For Payment"
                                ? "#FFC36A"
                                : item.status_name === "pending"
                                ? "#FFC36A"
                                : "white",
                          }}
                        >
                          {item.status_name === "Waiting For Payment"
                            ? t(`Processing`)
                            : t(item.status_name)}
                        </Typography.Text>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="3" className="px-6 py-4 text-center text-gray-400">
                  <NoDataComp />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {loading ? (
        <SmallLoader />
      ) : (
        <>
          {showViewMore && allTransactions?.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                margin: "20px 0",
                marginBottom: "100px",
              }}
            >
              <Pagination
                className="custom-pagination"
                current={pageData?.current_page}
                pageSize={10}
                total={pageData?.total}
                onChange={setPage}
                showSizeChanger={false}
                itemRender={itemRender}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Transactions;
